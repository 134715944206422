import React from 'react'
import { ButtonFeedbackStyled, ButtonStyled } from './styled'

const Button = ({ children, ...props }) => {
  return (
    <ButtonStyled {...props}>
      <ButtonFeedbackStyled />
      {children}
    </ButtonStyled>
  )
}

export default Button
