import React, { Fragment } from 'react'
import { IconBox } from '..'
import { DoubleCheckIcon } from '../../icons'
import { useChatSlice } from '../../store'
import Box from '../Box'
import MessageListItem from './MessageListItem'
import { decideSenderType, getTimeString } from './utils'
import LiveSessionMessage from '../LiveSessionMessage'
import { getUrlQuery } from '../../utils'
import DownloadAppMessage from '../DownloadAppMessage'

export default function MessageGroup({
  avatar,
  datetime,
  isread,
  messages,
  sender
}) {
  const {
    liveSession: {
      isLiveSessionUser,
      isLiveSessionAvailable,
      initialCredits,
      currentCredits
    },
    liveSessionMessagePreviousId
  } = useChatSlice()
  const senderType = decideSenderType(sender)
  const showSeenIcon = Boolean(isread && senderType === 'user')

  const showLiveSessionCTA =
    isLiveSessionUser &&
    isLiveSessionAvailable &&
    messages.some((message) => message.id === liveSessionMessagePreviousId)

  const showDownloadAppMessage =
    isLiveSessionUser &&
    messages.some((message) => message.id === liveSessionMessagePreviousId) &&
    currentCredits < initialCredits &&
    getUrlQuery('isNew', '0') === '1'

  return (
    <Fragment>
      <Box>
        {senderType === 'expert' && (
          <Box
            width={40}
            height={40}
            as="img"
            src={avatar}
            mb="0.5rem"
            borderRadius={999}
          />
        )}

        <Box>
          {messages.map((message, index) => (
            <MessageListItem
              key={message.id}
              {...message}
              index={index}
              messagesCount={messages.length}
            />
          ))}
        </Box>

        {senderType !== 'system' && (
          <Box
            display="flex"
            justifyContent={senderType === 'user' ? 'flex-end' : 'flex-start'}
            alignItems="center"
            mt="0.5rem"
            fontSize={12}
            lineHeight="14px"
            color="gray.400">
            {getTimeString(new Date(parseInt(datetime)))}
            {showSeenIcon && (
              <IconBox color="success" ml="0.25rem">
                <DoubleCheckIcon />
              </IconBox>
            )}
          </Box>
        )}
      </Box>

      {showLiveSessionCTA && <LiveSessionMessage />}
      {showDownloadAppMessage && <DownloadAppMessage />}
    </Fragment>
  )
}
