import React from 'react'
import { useTranslation } from '../../contexts'
import { FAQIcon } from '../../icons'
import { FAQ_DATA } from '../FAQ/data'
import CoachMenuItem from './CoachMenuItem'
import FAQModal from './FAQModal'
import { CoachMenuStyled } from './styled'

const CoachMenu = () => {
  const { t, translationLang } = useTranslation()
  const [isFAQActive, setIsFAQActive] = React.useState(false)

  if (!FAQ_DATA[translationLang]) return null

  return (
    <React.Fragment>
      <CoachMenuStyled>
        <CoachMenuItem
          Icon={FAQIcon}
          text={t('faqShort')}
          onClick={() => setIsFAQActive(true)}
        />
      </CoachMenuStyled>

      <FAQModal isActive={isFAQActive} close={() => setIsFAQActive(false)} />
    </React.Fragment>
  )
}

export default CoachMenu
