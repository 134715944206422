const objectGet = (obj, path, defaultValue) => {
  if (!obj || !path) throw new Error('Object and path are required.')

  let result = { ...obj }
  const pathBreakPoints = path.split('.')

  for (let index = 0; index < pathBreakPoints.length; index++) {
    const breakPoint = pathBreakPoints[index]
    if (result.hasOwnProperty(breakPoint)) {
      result = result[breakPoint]
    }
  }

  return result || defaultValue
}

const getThemeColor = (theme, lightKey, darkKey) => {
  const { mode, colors } = theme

  let colorKey = lightKey

  if (mode === 'dark' && darkKey) colorKey = darkKey

  return objectGet(colors, colorKey)
}

export const styledColor =
  (lightKey, darkKey) =>
  ({ theme }) =>
    getThemeColor(theme, lightKey, darkKey)

export const themeValue =
  (lightValue, darkValue) =>
  ({ theme }) =>
    theme.mode === 'light' ? lightValue : darkValue

export const ifDark =
  (styles) =>
  ({ theme }) =>
    theme.mode === 'dark' ? styles : ''
