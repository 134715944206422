import React from 'react'
import { getUrlQuery } from '../utils'
import languages from '../languages'

export const TranslationContext = React.createContext({
  lang: getUrlQuery('lang', 'en'),
  translationLang: getUrlQuery('lang', 'en'),
  t: (key = '') => '',
  isLangInitialized: false
})

export const TranslationProvider = ({ children }) => {
  const [lang, setLang] = React.useState(getUrlQuery('lang', 'en'))
  const [translationLang, setTranslationLang] = React.useState(
    getUrlQuery('lang', 'en')
  )
  const [isLangInitialized, setIsLangInitialized] = React.useState(false)
  const t = (key) => languages[translationLang][key]

  React.useEffect(() => {
    setLang(getUrlQuery('lang', 'en'))
    setTranslationLang(
      languages[getUrlQuery('lang', 'en')] ? getUrlQuery('lang', 'en') : 'en'
    )
    setIsLangInitialized(true)
  }, [])

  return (
    <TranslationContext.Provider
      value={{ lang, t, isLangInitialized, translationLang }}>
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslation = () => React.useContext(TranslationContext)
