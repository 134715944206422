const pt = {
  sendPlaceholder: 'Escreva sua mensagem',
  messagesCouldntLoad: 'Não conseguimos carregar suas mensagens.',
  tryAgain: 'Tentar novamente',
  errorCode: 'Código do erro',
  downloadApp: 'Baixe o aplicativo para ter a melhor experiência',
  download: 'Baixar',
  bookVideoCall: 'Agendar uma sessão',
  faqTitle: 'Dúvidas frequentes',
  faqShort: 'FAQ',
  liveSessionMessage:
    'Você tem uma sessão ao vivo disponível. Clique para agendar um horário específico para se consultar com seu psicólogo.',
  liveSessionMessageTherapy:
    'Você tem uma sessão ao vivo disponível. Clique para agendar um horário específico para se consultar com seu psicólogo.',
  bookLiveSession: 'Agendar sessão ao vivo',
  liveBoxTitle: 'Sessões ao vivo',
  liveBoxDesc: 'Vamos agendar sua sessão',
  extraLiveBoxTitle: 'Precisa de mais sessões?',
  extraLiveBoxDesc: 'Podemos ajudar!',
  extraLiveBoxButton: 'Comprar sessão ao vivo',
  elPriceModalTitle: 'Você vai comprar uma sessão ao vivo adicional.',
  elPriceModalDesc: (price, currency, cardNumber) =>
    `Será feita uma cobrança de ${price} ${currency} no seu cartão com final ${cardNumber}.`,
  continue: 'Continuar',
  cancel: 'Cancelar',
  elSuccessModalTitle: 'Parabéns!',
  elSuccessModalDesc:
    'Você tem um novo crédito de sessão. Agora, pode agendar sua sessão ao vivo.',
  elFailModalTitle: 'Houve uma falha no pagamento.',
  elFailModalDesc: 'Adicione outro cartão ou tente novamente mais tarde.',
  addNewCard: 'Adicionar novo cartão',
  elWarningModalTitle: 'Não temos as informações do seu cartão.',
  elWarningModalDesc:
    'Adicione um cartão à sua conta para comprar sessões ao vivo.',
  downloadAppMessageText:
    'Você agendou sua primeira sessão. Agora, faça o download do aplicativo e acesse para ver seu programa personalizado',
  downloadAppMessageButton: 'Baixar o aplicativo'
}

export default pt
