const de = {
  sendPlaceholder: 'Gib deine Nachricht ein',
  messagesCouldntLoad: 'Wir können deine Nachrichten im Moment nicht laden.',
  tryAgain: 'Nochmal versuchen',
  errorCode: 'Fehlercode',
  downloadApp: 'Lade die App für das beste Erlebnis herunter',
  download: 'Herunterladen',
  bookVideoCall: 'Sitzung buchen',
  faqTitle: 'Häufig gestellte Fragen',
  faqShort: 'FAQ',
  liveSessionMessage:
    'Du hast eine Live-Sitzung verfügbar. Klicke hier, um einen Termin für ein Treffen mit deinem Psychologen zu buchen.',
  liveSessionMessageTherapy:
    'Du hast eine Live-Sitzung verfügbar. Klicke hier, um einen Termin für ein Treffen mit deinem Psychologen zu buchen.',
  bookLiveSession: 'Buche eine Live-Sitzung',
  liveBoxTitle: 'Live-Sitzungen',
  liveBoxDesc: 'Lass uns deine Sitzung buchen',
  extraLiveBoxTitle: 'Brauchst du mehr Sitzungen?',
  extraLiveBoxDesc: 'Wir können helfen!',
  extraLiveBoxButton: 'Eine Live-Sitzung kaufen',
  elPriceModalTitle: 'Du kaufst eine zusätzliche Live-Sitzung.',
  elPriceModalDesc: (price, currency, cardNumber) =>
    `Eine Zahlung von ${price} ${currency} wird von deiner Karte mit der Endung ${cardNumber} abgebucht.`,
  continue: 'Weiter',
  cancel: 'Abbrechen',
  elSuccessModalTitle: 'Herzlichen Glückwunsch!',
  elSuccessModalDesc:
    'Du hast ein neues Guthaben für eine Sitzung. Jetzt kannst du deine Live-Sitzung buchen.',
  elFailModalTitle: 'Deine Zahlung ist fehlgeschlagen.',
  elFailModalDesc:
    'Bitte füge eine neue Karte hinzu oder versuche es später noch einmal.',
  addNewCard: 'Eine neue Karte hinzufügen',
  elWarningModalTitle: 'Wir haben deine Kartendaten nicht.',
  elWarningModalDesc:
    'Bitte füge eine Karte zu deinem Konto hinzu, um Live-Sitzungen zu kaufen.',
  downloadAppMessageText:
    'Du hast deine erste Sitzung gebucht. Lade jetzt die App herunter und melde dich an, um dein persönliches Programm zu erstellen',
  downloadAppMessageButton: 'Die App herunterladen'
}

export default de
