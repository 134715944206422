import React from 'react'
import {
  MessageBoxStyled,
  MessageListItemStyled,
  MessageStyled
} from './styled'
import { decideSenderType } from './utils'

const MessageListItem = ({ message, sender, index, messagesCount }) => {
  const senderType = decideSenderType(sender)

  return (
    <MessageListItemStyled sender={senderType} className="messagelistitem">
      <MessageBoxStyled
        sender={senderType}
        index={index}
        messagesCount={messagesCount}>
        <MessageStyled
          dangerouslySetInnerHTML={{
            __html: message.replace(/\r?\n/g, '<br />')
          }}
        />
      </MessageBoxStyled>
    </MessageListItemStyled>
  )
}

export default MessageListItem
