import styled from 'styled-components'

export const DownloadBannerStyled = styled.div`
  padding: 0.5rem 0.75rem;
  background-color: #2c74f4ee;
  color: white;
  font-weight: 500;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  font-size: 0.875rem;

  button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: white;
    color: #2c74f4;
    border-radius: 999px;
    padding: 0.5rem 0.875rem;
    line-height: 1;
    text-decoration: none;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`
