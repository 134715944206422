import { configureStore } from '@reduxjs/toolkit'
import chatSlice from './slices/chatSlice'

const store = configureStore({
  reducer: {
    chat: chatSlice.reducer
  }
})

export * from './slices/chatSlice'
export default store
