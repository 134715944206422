import React from 'react'
import Box from '../Box'
import { LoaderStyled } from './styled'

const Loader = () => {
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <LoaderStyled />
    </Box>
  )
}

export default Loader
