import React from 'react'

const Brand = () => {
  return (
    <svg
      width="99"
      height="14"
      viewBox="0 0 99 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6465 2.54242C3.66099 2.49235 3.69129 2.44817 3.73299 2.4163C3.77469 2.38442 3.8256 2.36654 3.87831 2.36523H4.08263C4.1287 2.36584 4.17366 2.37927 4.21235 2.40398C4.25104 2.42869 4.28189 2.46367 4.30135 2.50491L6.84349 9.48874H6.90766L9.41051 2.50491C9.42975 2.46377 9.46036 2.42884 9.49882 2.40412C9.53729 2.37941 9.58204 2.36593 9.62792 2.36523H9.83354C9.88609 2.36661 9.9368 2.38454 9.97828 2.41643C10.0198 2.44831 10.0498 2.49246 10.0641 2.54242L11.7261 11.0614C11.764 11.2386 11.6868 11.3653 11.4942 11.3653H10.9808C10.9288 11.3616 10.879 11.3427 10.838 11.3109C10.7969 11.2791 10.7664 11.2359 10.7503 11.1868L9.60827 4.65697H9.55589L7.19842 11.3498C7.17758 11.3897 7.14657 11.4236 7.10845 11.4481C7.07033 11.4726 7.02641 11.4868 6.98101 11.4895H6.74526C6.70012 11.4871 6.65638 11.4732 6.61828 11.4492C6.58018 11.4252 6.54903 11.3918 6.52785 11.3524L4.14026 4.65697H4.08787L2.972 11.1868C2.95738 11.237 2.92708 11.2812 2.88544 11.3133C2.8438 11.3453 2.79295 11.3635 2.74018 11.3653H2.22678C2.03425 11.3653 1.95698 11.236 1.99627 11.0614L3.6465 2.54242Z"
        fill="currentColor"
      />
      <path
        d="M17.1172 2.73306C17.1172 2.66914 17.1428 2.60782 17.1885 2.5625C17.2341 2.51718 17.2961 2.49155 17.3608 2.49121H22.4189C22.451 2.49121 22.4828 2.49748 22.5124 2.50964C22.542 2.52181 22.569 2.53965 22.5916 2.56212C22.6142 2.5846 22.6322 2.61127 22.6443 2.64061C22.6565 2.66994 22.6627 2.70136 22.6625 2.73306V3.10424C22.6625 3.16804 22.6368 3.22922 22.5911 3.27433C22.5455 3.31945 22.4835 3.34479 22.4189 3.34479H18.106V6.4332H21.7902C21.8543 6.43547 21.9152 6.46171 21.9604 6.50662C22.0056 6.55153 22.0319 6.61174 22.0338 6.67505V7.04234C22.0338 7.07393 22.0275 7.10521 22.0153 7.1344C22.003 7.16358 21.9851 7.1901 21.9625 7.21244C21.9399 7.23478 21.913 7.2525 21.8834 7.26459C21.8539 7.27668 21.8222 7.2829 21.7902 7.2829H18.106V10.5162H22.4189C22.4835 10.5162 22.5455 10.5415 22.5911 10.5866C22.6368 10.6317 22.6625 10.6929 22.6625 10.7567V11.1227C22.6625 11.1865 22.6368 11.2477 22.5911 11.2928C22.5455 11.3379 22.4835 11.3633 22.4189 11.3633H17.3608C17.2962 11.3633 17.2342 11.3379 17.1885 11.2928C17.1429 11.2477 17.1172 11.1865 17.1172 11.1227V2.73306Z"
        fill="currentColor"
      />
      <path
        d="M28.0079 2.73273C28.0068 2.6707 28.0303 2.61071 28.0733 2.56553C28.1164 2.52035 28.1756 2.49357 28.2384 2.49088H31.1132C31.7096 2.48082 32.3022 2.58815 32.8562 2.8066C33.4102 3.02505 33.9147 3.35025 34.3401 3.76323C34.7655 4.17621 35.1034 4.6687 35.334 5.21198C35.5647 5.75527 35.6835 6.33846 35.6835 6.92755C35.6835 7.51665 35.5647 8.09984 35.334 8.64313C35.1034 9.18641 34.7655 9.6789 34.3401 10.0919C33.9147 10.5049 33.4102 10.8301 32.8562 11.0485C32.3022 11.267 31.7096 11.3743 31.1132 11.3642H28.2318C28.1702 11.3599 28.1126 11.3324 28.071 11.2874C28.0293 11.2424 28.0067 11.1834 28.0079 11.1224V2.73273ZM30.9979 10.4925C33.1026 10.4925 34.58 9.01042 34.58 6.91915C34.58 4.82788 33.1026 3.35739 30.9979 3.35739H28.9757V10.4887L30.9979 10.4925Z"
        fill="currentColor"
      />
      <path
        d="M41.7726 2.49121H41.2592C41.1247 2.49121 41.0156 2.59891 41.0156 2.73177V11.1227C41.0156 11.2556 41.1247 11.3633 41.2592 11.3633H41.7726C41.9072 11.3633 42.0162 11.2556 42.0162 11.1227V2.73177C42.0162 2.59891 41.9072 2.49121 41.7726 2.49121Z"
        fill="currentColor"
      />
      <path
        d="M49.5492 3.34091H47.3149C47.251 3.34092 47.1896 3.31611 47.144 3.27183C47.0984 3.22755 47.0723 3.16736 47.0713 3.10424V2.73306C47.0711 2.70136 47.0773 2.66994 47.0895 2.64061C47.1016 2.61127 47.1195 2.5846 47.1422 2.56212C47.1648 2.53965 47.1917 2.52181 47.2214 2.50964C47.251 2.49748 47.2828 2.49121 47.3149 2.49121H52.7593C52.7914 2.49121 52.8232 2.49748 52.8529 2.50964C52.8825 2.52181 52.9094 2.53965 52.932 2.56212C52.9547 2.5846 52.9726 2.61127 52.9848 2.64061C52.9969 2.66994 53.0031 2.70136 53.0029 2.73306V3.10424C53.0019 3.16736 52.9758 3.22755 52.9302 3.27183C52.8846 3.31611 52.8233 3.34092 52.7593 3.34091H50.525V11.1227C50.5227 11.186 50.4961 11.2461 50.4506 11.2907C50.4051 11.3354 50.3442 11.3613 50.2801 11.3633H49.7929C49.7289 11.3613 49.668 11.3354 49.6228 11.2907C49.5775 11.246 49.5512 11.1859 49.5492 11.1227V3.34091Z"
        fill="currentColor"
      />
      <path
        d="M61.6994 2.36526C62.6137 2.36245 63.5082 2.62744 64.2699 3.12672C65.0316 3.626 65.6263 4.33713 65.9787 5.17017C66.331 6.00322 66.4253 6.92074 66.2496 7.8067C66.0738 8.69266 65.6359 9.50724 64.9913 10.1474C64.3466 10.7876 63.5242 11.2246 62.628 11.4032C61.7318 11.5818 60.8022 11.4939 59.9566 11.1506C59.111 10.8074 58.3874 10.2242 57.8775 9.47491C57.3676 8.72558 57.0942 7.84375 57.0918 6.94096C57.0891 6.34148 57.2062 5.74737 57.4364 5.19267C57.6667 4.63798 58.0056 4.13363 58.4336 3.70853C58.8617 3.28343 59.3705 2.94595 59.9309 2.71544C60.4913 2.48493 61.0923 2.36593 61.6994 2.36526ZM61.6994 10.6049C62.4372 10.6085 63.1594 10.3955 63.7744 9.99314C64.3894 9.59074 64.8695 9.01698 65.1538 8.34469C65.438 7.6724 65.5136 6.93188 65.371 6.2171C65.2283 5.50232 64.8738 4.84549 64.3525 4.32998C63.8312 3.81446 63.1665 3.4635 62.4429 3.32161C61.7192 3.17973 60.9692 3.25333 60.288 3.53307C59.6068 3.81281 59.0251 4.28608 58.6167 4.89282C58.2083 5.49957 57.9917 6.21243 57.9942 6.94096C57.9994 7.91035 58.3912 8.83869 59.0847 9.52477C59.7783 10.2108 60.7177 10.5994 61.6994 10.6062"
        fill="currentColor"
      />
      <path
        d="M71.7148 2.73287C71.7147 2.70117 71.7209 2.66975 71.733 2.64041C71.7452 2.61108 71.7631 2.58441 71.7857 2.56193C71.8084 2.53946 71.8353 2.52162 71.8649 2.50945C71.8946 2.49728 71.9264 2.49102 71.9585 2.49102H74.7953C75.1763 2.48216 75.5552 2.54849 75.9099 2.68614C76.2646 2.82379 76.5879 3.02998 76.8608 3.29263C77.1338 3.55527 77.3509 3.86908 77.4994 4.21566C77.6479 4.56223 77.7248 4.9346 77.7257 5.31093C77.7266 5.68725 77.6514 6.05996 77.5045 6.40721C77.3575 6.75445 77.1419 7.06924 76.8702 7.33311C76.5985 7.59698 76.2761 7.80463 75.9221 7.94389C75.568 8.08314 75.1894 8.15119 74.8084 8.14405H72.6906V11.1225C72.6886 11.1857 72.6623 11.2458 72.617 11.2905C72.5718 11.3352 72.511 11.3611 72.447 11.3631H71.9585C71.8939 11.3631 71.8319 11.3377 71.7862 11.2926C71.7405 11.2475 71.7148 11.1863 71.7148 11.1225V2.73287ZM74.7272 7.2064C74.986 7.21157 75.2432 7.16482 75.4832 7.06899C75.7232 6.97316 75.941 6.83024 76.1234 6.64888C76.3058 6.46753 76.4491 6.2515 76.5445 6.01388C76.64 5.77625 76.6856 5.52198 76.6786 5.26645C76.6786 4.13739 75.7802 3.45582 74.7272 3.45582H72.6906V7.2064H74.7272Z"
        fill="currentColor"
      />
      <path
        d="M83.5793 2.49121H83.0659C82.9313 2.49121 82.8223 2.59891 82.8223 2.73177V11.1227C82.8223 11.2556 82.9313 11.3633 83.0659 11.3633H83.5793C83.7138 11.3633 83.8229 11.2556 83.8229 11.1227V2.73177C83.8229 2.59891 83.7138 2.49121 83.5793 2.49121Z"
        fill="currentColor"
      />
      <path
        d="M88.6291 11.0349L92.532 2.49912C92.5497 2.45688 92.58 2.42092 92.6188 2.39599C92.6576 2.37106 92.7031 2.35832 92.7494 2.35944H92.8804C92.9271 2.35899 92.9728 2.37261 93.0114 2.3985C93.05 2.42438 93.0797 2.46129 93.0965 2.50429L96.9732 11.0401C96.9923 11.0757 97.0014 11.1157 96.9995 11.1559C96.9977 11.1961 96.9849 11.2351 96.9625 11.2688C96.9402 11.3025 96.9091 11.3296 96.8725 11.3474C96.8359 11.3652 96.7952 11.373 96.7545 11.3699H96.2398C96.1935 11.3701 96.1482 11.357 96.1094 11.3322C96.0706 11.3074 96.0399 11.2719 96.0211 11.2302L95.0715 9.13894H90.5164L89.5786 11.2302C89.5603 11.272 89.5299 11.3076 89.4913 11.3324C89.4526 11.3573 89.4074 11.3703 89.3612 11.3699H88.8478C88.807 11.3734 88.766 11.3659 88.7292 11.3483C88.6923 11.3306 88.661 11.3034 88.6386 11.2696C88.6162 11.2357 88.6034 11.1965 88.6018 11.1561C88.6001 11.1157 88.6095 11.0756 88.6291 11.0401V11.0349ZM94.7271 8.35261C94.0971 6.97136 93.4816 5.57718 92.8529 4.19593H92.7494L90.87 8.34743L94.7271 8.35261Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default React.memo(Brand)
