import React from 'react'

const Send = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1901_1460)">
        <path
          d="M15.4515 0.548376C15.2092 0.303089 14.9032 0.130332 14.568 0.0495644C14.2328 -0.0312031 13.8818 -0.0167715 13.5543 0.0912334L1.26861 4.19409C0.969063 4.29491 0.700091 4.47029 0.487009 4.70373C0.273926 4.93716 0.123741 5.22097 0.0505859 5.52845C-0.0225692 5.83593 -0.0162882 6.15697 0.0688377 6.46135C0.153964 6.76574 0.315135 7.04346 0.537185 7.26838L3.42861 10.1369C3.4807 10.1927 3.50935 10.2664 3.50861 10.3427L3.42861 13.9427C3.42453 14.0406 3.44676 14.1378 3.49299 14.2242C3.53921 14.3107 3.60775 14.3831 3.69147 14.4341C3.77834 14.4842 3.87688 14.5106 3.97718 14.5106C4.07749 14.5106 4.17603 14.4842 4.2629 14.4341L6.37718 13.2912C6.43093 13.2626 6.49268 13.2527 6.55268 13.263C6.61269 13.2734 6.66755 13.3034 6.70861 13.3484L8.75433 15.3941C9.09034 15.7553 9.55317 15.9724 10.0458 15.9998C10.1862 16.0173 10.3282 16.0173 10.4686 15.9998C10.7785 15.9297 11.0648 15.7801 11.2993 15.5656C11.5338 15.3512 11.7083 15.0794 11.8058 14.7769L15.9086 2.44552C16.0166 2.11809 16.031 1.76701 15.9503 1.43183C15.8695 1.09664 15.6968 0.790671 15.4515 0.548376ZM12.4343 4.42266L6.40004 11.0055C6.3637 11.0451 6.31954 11.0767 6.27036 11.0983C6.22119 11.12 6.16805 11.1312 6.11433 11.1312C6.00824 11.1312 5.9065 11.0891 5.83148 11.0141C5.75647 10.9391 5.71433 10.8373 5.71433 10.7312V8.30838C5.71405 8.12117 5.75975 7.93677 5.84744 7.77137C5.93512 7.60597 6.06209 7.46464 6.21718 7.3598L11.7829 3.65695C11.8624 3.60966 11.9532 3.5847 12.0458 3.5847C12.1383 3.5847 12.2291 3.60966 12.3086 3.65695C12.3895 3.69944 12.457 3.76367 12.5035 3.84241C12.5499 3.92114 12.5734 4.01127 12.5715 4.10266C12.5653 4.22226 12.5167 4.33573 12.4343 4.42266Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1901_1460">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default React.memo(Send)
