import React from 'react'
import Loader from '../Loader'
import { BackdropStyled } from './styled'

const LoaderWithBackdrop = ({ loading }) => {
  if (!loading) return null
  return (
    <BackdropStyled>
      <Loader />
    </BackdropStyled>
  )
}

export default LoaderWithBackdrop
