import React from 'react'
import Box from '../Box'
import { AvatarStyled } from './styled'

const Expert = ({ name, description, title, avatar }) => {
  return (
    <Box
      textAlign="center"
      display="flex"
      flexDirection="column"
      alignItems="center">
      <Box width="6.25rem" height="6.25rem" mb="0.75rem">
        <AvatarStyled src={avatar} />
      </Box>

      <Box mb="0.25rem" fontWeight="500" fontSize="1.25rem" lineHeight="1.5rem">
        {name}
      </Box>
      <Box
        mb="0.75rem"
        color="gray.600"
        _dark={{ color: 'gray.400' }}
        lineHeight="1.125rem">
        {title}
      </Box>
      <Box color="gray.600" _dark={{ color: 'gray.400' }} lineHeight="1.25rem">
        {description}
      </Box>
    </Box>
  )
}

export default Expert
