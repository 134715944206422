import React from 'react'
import { useTranslation } from '../../contexts'
import Box from '../Box'
import Button from '../Button'

const ErrorPage = () => {
  const { t } = useTranslation()

  const handleClose = () => {
    window.onCloseClick && window.onCloseClick()
    window.location = `${window.location.origin}/close`
  }

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        width="90%"
        maxWidth="30rem"
      >
        <Box fontSize="1.125rem" mb="1rem">
          {t('messagesCouldntLoad')}
        </Box>

        <Button onClick={handleClose}>{t('tryAgain')}</Button>

        <Box mt="0.5rem" opacity="0.5" fontSize="0.75rem">
          {t('errorCode')}: 99
        </Box>
      </Box>
    </Box>
  )
}

export default ErrorPage
