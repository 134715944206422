import styled from 'styled-components'
import { styledColor, themeValue } from '../../utils'

export const HeaderWrapperStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: ${styledColor('white.100', 'black.100')};
  box-shadow: ${themeValue(
    '0px 2px 15px rgba(0, 0, 0, 0.1)',
    '0px 1px 0px rgba(255, 255, 255, 0.16);'
  )};
`

export const HeaderStyled = styled.header`
  width: 100%;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
