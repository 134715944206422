import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import App from './App'
import { TranslationProvider } from './contexts'
import { GlobalStyles } from './globalStyles'
import store from './store'
import theme from './theme'
import { getUrlQuery } from './utils'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <TranslationProvider>
        <ThemeProvider
          theme={{ ...theme, mode: getUrlQuery('theme', 'light') }}>
          <GlobalStyles />
          <App />
        </ThemeProvider>
      </TranslationProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
