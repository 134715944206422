const es = {
  sendPlaceholder: 'Escribe tu mensaje',
  messagesCouldntLoad: 'No hemos podido cargar tus mensajes en este momento',
  tryAgain: 'Intentar de nuevo',
  errorCode: 'Código de error',
  downloadApp: 'Descarga la app para una mejor experiencia',
  download: 'Descargar',
  bookVideoCall: 'Agenda una sesión',
  faqTitle: 'Preguntas frecuentes',
  faqShort: 'FAQ',
  liveSessionMessage:
    'Tienes una sesión en vivo disponible. Haz clic para reservar una hora específica y reunirte con tu psicólogo.',
  liveSessionMessageTherapy:
    'Tienes una sesión en vivo disponible. Haz clic para reservar una hora específica y reunirte con tu psicólogo.',
  bookLiveSession: 'Agendar una sesión en vivo',
  liveBoxTitle: 'Sesiones en vivo',
  liveBoxDesc: 'Agendemos tu sesión',
  extraLiveBoxTitle: '¿Necesitas más sesiones?',
  extraLiveBoxDesc: '¡Podemos ayudar!',
  extraLiveBoxButton: 'Comprar sesión en vivo',
  elPriceModalTitle: 'Estás adquiriendo una nueva sesión en vivo.',
  elPriceModalDesc: (price, currency, cardNumber) =>
    `Se deducirá de tu tarjeta terminada en ${cardNumber} un pago de ${price} ${currency}.`,
  continue: 'Continuar',
  cancel: 'Cancelar',
  elSuccessModalTitle: '¡Felicitaciones!',
  elSuccessModalDesc:
    'Tienes un nuevo crédito de sesión. Ahora, puedes reservar tu sesión en vivo.',
  elFailModalTitle: 'Tu pago ha fallado.',
  elFailModalDesc: 'Por favor agrega una nueva tarjeta e inténtalo de nuevo.',
  addNewCard: 'Agregar nueva tarjeta',
  elWarningModalTitle: 'No tenemos los datos de tu tarjeta.',
  elWarningModalDesc:
    'Agrega una tarjeta a tu cuenta para comprar sesiones en vivo.',
  downloadAppMessageText:
    'Has reservado tu primera sesión. Ahora descarga la aplicación e inicia sesión para encontrar tu programa personalizado.',
  downloadAppMessageButton: 'Descarga la app'
}

export default es
