import React from 'react'
import {
  Header,
  MessagesList,
  NewMessageField
  // UserTutorial
} from '../../components'
// import { isTutorialSeenBefore } from '../../utils'

const ChatScreen = () => {
  // const [isTutorialAvailable, setIsTutorialAvailable] = React.useState(
  //   !isTutorialSeenBefore()
  // )

  return (
    <React.Fragment>
      {/* {isTutorialAvailable && (
        <UserTutorial finishTutorial={() => setIsTutorialAvailable(false)} />
      )} */}

      <Header />

      <MessagesList />

      <NewMessageField />
    </React.Fragment>
  )
}

export default ChatScreen
