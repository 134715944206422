import styled from 'styled-components'
import { styledColor } from '../../utils'
import Button from '../Button'

export const LiveSessionMessageStyled = styled.div`
  border-top: 1px solid ${styledColor('gray.0', 'white.08')};
  padding: 1.5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
`

export const ButtonStyled = styled(Button)`
  width: fit-content;
  padding: 0.75rem 1.5rem;
`
