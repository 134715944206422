export const getHashValue = (key, defaultValue) => {
  let hashes = window.location.hash
  if (hashes.length === 0) return defaultValue

  const hashObject = {}

  hashes = hashes.slice(1, hashes.length)
  const params = hashes.split('&')

  for (let i = 0; i < params.length; i++) {
    const param = params[i]
    const [key, value] = param.split('=')
    hashObject[key] = value
  }

  return hashObject[key] || defaultValue
}

export const getUrlQuery = (key, defaultValue = '') => {
  const urlParams = new URLSearchParams(window.location.search)
  const urlPramValue = urlParams.get(key)

  if (urlPramValue) return urlPramValue

  const hashValue = getHashValue(key, defaultValue)

  return hashValue
}
