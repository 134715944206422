import styled, { css } from 'styled-components'
import { ifDark, styledColor } from '../../utils'

export const LiveSessionBoxStyled = styled.div`
  background: ${styledColor('primary.light.100')}29;
  border: 1px solid ${styledColor('primary.light.100')}29;
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  padding: 1rem 1.5rem 1rem 1rem;
  display: flex;

  ${ifDark(css`
    border-color: ${styledColor('primary.light.100')}a3;
    box-shadow: none;
  `)}
`

export const IconContainerStyled = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${styledColor('primary.main')};
  color: white;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
`
