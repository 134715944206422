import styled, { css } from 'styled-components'
import { ifDark, styledColor } from '../../utils'
import Button from '../Button'

export const LiveSessionBoxStyled = styled.div`
  background: ${styledColor('secondary.light.100')}29;
  border: 1px solid ${styledColor('secondary.light.100')}29;
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  padding: 1rem 1.5rem 1rem 1rem;
  display: flex;

  ${ifDark(css`
    border-color: ${styledColor('secondary.light.100')}a3;
    box-shadow: none;
  `)}
`

export const IconContainerStyled = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${styledColor('secondary.main')};
  color: white;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
`

export const ButtonStyled = styled(Button)`
  color: ${styledColor('secondary.main', 'white.100')};
  background: ${styledColor('white.100', 'black.16')};
  border: 1px solid ${styledColor('gray.100', 'white.24')};
  box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.06);

  ${ifDark(css`
    box-shadow: none;
  `)}
`

export const BackdropStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PaymentModalStyled = styled.div`
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: ${styledColor('white.100')};
  border-radius: 0.5rem;
  padding: 1.5rem;
`

export const ServiceErrorStyled = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${styledColor('warning')};
  color: ${styledColor('warning')};
  background-color: ${styledColor('warning')}24;
  padding: 0.5rem;
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
`
