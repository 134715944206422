import React from 'react'
import { IconBox } from '..'
import Box from '../Box'
import ArrowRight from '../../icons/ArrowRight'
import { CoachMenuItemIconStyled, CoachMenuItemStyled } from './styled'

const CoachMenuItem = ({ Icon, text, ...props }) => {
  return (
    <CoachMenuItemStyled {...props}>
      <CoachMenuItemIconStyled>
        <Icon />
      </CoachMenuItemIconStyled>

      <Box flex="1" color="gray.800" _dark={{ color: 'white.100' }}>
        {text}
      </Box>

      <IconBox color="gray.700" _dark={{ color: 'white.100' }}>
        <ArrowRight />
      </IconBox>
    </CoachMenuItemStyled>
  )
}

export default CoachMenuItem
