const tr = {
  sendPlaceholder: 'Mesajını yaz',
  messagesCouldntLoad: 'Şu anda mesajlarınız yüklenememektedir!',
  tryAgain: 'Yeniden Dene',
  errorCode: 'Hata Kodu',
  downloadApp: 'Daha iyi bir deneyim için uygulamayı indir',
  download: 'İndir',
  bookVideoCall: 'Görüşme ayarla',
  faqTitle: 'Sıkça Sorulan Sorular',
  faqShort: 'SSS',
  liveSessionMessage:
    'Kullanabileceğin canlı görüşme hakkın var. Koçunla video, ses veya mesajlaşma yoluyla konuşmak üzere belirli bir zaman ayırmak için tıklayabilirsin.',
  liveSessionMessageTherapy:
    'Kullanabileceğin canlı görüşme hakkın var. Psikoloğunla görüşmek üzere belirli bir zaman ayırmak için tıklayabilirsin.',
  bookLiveSession: 'Canlı görüşme ayarla',
  liveBoxTitle: 'Canlı görüşmeler',
  liveBoxDesc: 'Hadi koçunla canlı görüşmeni ayarlayalım',
  extraLiveBoxTitle: 'Daha fazla canlı görüşmeye mi ihtiyacın var?',
  extraLiveBoxDesc: 'Sana yardımcı olabiliriz!',
  extraLiveBoxButton: 'Canlı görüşme satın al',
  elPriceModalTitle: 'Ekstra canlı görüşme satın almak üzeresin.',
  elPriceModalDesc: (price, currency, cardNumber) =>
    `Sonu ${cardNumber} ile biten kartından ${price} ${currency} tutarında ödeme alınacak.`,
  continue: 'Devam et',
  cancel: 'Vazgeç',
  elSuccessModalTitle: 'Tebrikler!',
  elSuccessModalDesc:
    'Yeni bir görüşme hakkın var. Şimdi canlı görüşmeni planlayabilirsin.',
  elFailModalTitle: 'Ödemen başarısız oldu.',
  elFailModalDesc: 'Lütfen yeni bir kart ekle veya daha sonra tekrar dene.',
  addNewCard: 'Yeni bir kart ekle',
  elWarningModalTitle: 'Kart bilgilerin mevcut değil.',
  elWarningModalDesc:
    'Canlı görüşme satın alabilmek için lütfen hesabına bir kart ekle.',
  downloadAppMessageText:
    'İlk görüşmeni planladın. Şimdi, kişisel programına ulaşmak için uygulamayı indir ve hesabına giriş yap.',
  downloadAppMessageButton: 'Uygulamayı indir'
}

export default tr
