import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { styledColor } from '../../utils'

export const FAQItemStyled = styled.div`
  border-radius: 0.4rem;
  overflow: hidden;

  ${ifProp(
    'isActive',
    css`
      border: 1px solid ${styledColor('gray.100', 'white.16')};
      background: ${styledColor('white.100', 'white.16')};
    `,
    css`
      border: 1px solid ${styledColor('black.08', 'white.08')};
      background: ${styledColor('black.08', 'white.08')};
    `
  )};

  &:not(:last-child) {
    margin-bottom: 0.875rem;
  }
`

export const FAQItemTitleStyled = styled.button`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: ${styledColor('gray.600', 'white.100')};
  padding: 0.75rem 0.875rem;
  width: 100%;
  cursor: pointer;
  position: relative;
  transition: 0.2s ease-in-out;
  padding-right: 2.5rem;

  background: transparent;
`

export const FAQItemIconStyled = styled.div`
  position: absolute;
  right: 0.875rem;
  transition: 0.2s ease-in-out;

  transform: rotate(${ifProp('isActive', '180deg', '0')});
`

export const FAQItemTextStyled = styled.div`
  padding: 0 0.875rem;
  overflow-y: auto;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${styledColor('black.100', 'white.100')};
  transition: 0.2s ease-in-out;

  ${ifProp(
    'isActive',
    css`
      max-height: 10rem;
      padding: 1rem 0.875rem 1rem;
      margin-bottom: 1rem;
    `,
    css`
      max-height: 0;
      padding: 0 0.875rem;
    `
  )};
`
