import TextareaAutosize from 'react-textarea-autosize'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'
import { styledColor, themeValue } from '../../utils'

export const NewMessageFieldWrapperStyled = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`

export const NewMessageFieldContainerStyled = styled.form`
  width: 100%;
  transition: 0.4s ease-in-out;
  padding: 1rem 1.5rem ${ifProp('isFocused', '1rem', '3rem')};
  background-color: ${styledColor('white.100', 'gray.800')};
  box-shadow: ${themeValue(
    '-2px 0px 15px rgba(0, 0, 0, 0.1)',
    '0px -1px 0px rgba(255, 255, 255, 0.16);'
  )};
  display: flex;
  align-items: flex-end;
`

export const TextAreaStyled = styled(TextareaAutosize)`
  flex: 1;
  background: ${styledColor('gray.0', 'black.100')};
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  border: none;
  outline: none;
  resize: none;
  font-size: 1rem;
  line-height: 1.5;
  transition: 0.1s ease-in-out height, 0.4s ease-in-out border-color;
  color: ${styledColor('gray.800', 'white.100')};

  &:focus {
    border: 1px solid ${styledColor('primary.light.100', 'secondary.light.100')};
  }

  &::placeholder {
    color: ${styledColor('gary.400')};
  }
`

export const SendButtonStyled = styled.button`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 999rem;
  margin-left: 1rem;
  transition: 0.2s ease-in-out;

  ${ifProp(
    'isActive',
    css`
      color: ${styledColor('white.100')};
      background-color: ${styledColor('primary.main', 'secondary.main')};
    `,
    css`
      background-color: ${styledColor('gray.0', 'white.16')};
      color: ${styledColor('primary.main', 'white.100')};
    `
  )}
`
