import React from 'react'
import Expand from '../../icons/Expand'
import {
  FAQItemIconStyled,
  FAQItemStyled,
  FAQItemTextStyled,
  FAQItemTitleStyled
} from './styled'

const FAQItem = ({ title, text }) => {
  const [isActive, setIsActive] = React.useState(false)
  const toggleIsActive = () => setIsActive((current) => !current)

  return (
    <FAQItemStyled isActive={isActive}>
      <FAQItemTitleStyled onClick={toggleIsActive} isActive={isActive}>
        {title}

        <FAQItemIconStyled isActive={isActive}>
          <Expand />
        </FAQItemIconStyled>
      </FAQItemTitleStyled>

      <FAQItemTextStyled
        isActive={isActive}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </FAQItemStyled>
  )
}

export default FAQItem
