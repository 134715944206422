const fr = {
  sendPlaceholder: 'Tape ton message',
  messagesCouldntLoad:
    "Nous n'avons pas pu charger tes messages pour le moment.",
  tryAgain: 'Essaie à nouveau',
  errorCode: "Code d'erreur",
  downloadApp:
    "Télécharge l'application pour une expérience de coaching optimale",
  download: 'Télécharger',
  bookVideoCall: 'Réserve une session',
  faqTitle: 'Foire Aux Questions',
  faqShort: 'FAQ',
  liveSessionMessage:
    'Une session en direct avec ton coach est disponible. Clique pour réserver un horaire spécifique pour parler avec ton coach par vidéo, audio ou texte.',
  liveSessionMessageTherapy:
    'Tu as une session en direct disponible. Clique pour réserver un horaire spécifique pour rencontrer ton psychologue.',
  bookLiveSession: 'Réserve une session en direct',
  liveBoxTitle: 'Sessions en direct',
  liveBoxDesc: 'Réservons ta session',
  extraLiveBoxTitle: 'As-tu besoin de plus de sessions en direct?',
  extraLiveBoxDesc: "Nous pouvons t'aider!",
  extraLiveBoxButton: 'Acheter une session en direct',
  elPriceModalTitle:
    "Tu es sur le point d'acheter une session en direct supplémentaire.",
  elPriceModalDesc: (price, currency, cardNumber) =>
    `Un paiement de ${price}${currency} sera débité de la carte terminant par ${cardNumber}.`,
  continue: 'Continuer',
  cancel: 'Annuler',
  elSuccessModalTitle: 'Félicitations!',
  elSuccessModalDesc:
    "Tu disposes d'un nouveau crédit pour une session. Maintenant, tu peux réserver ta session en direct.",
  elFailModalTitle: 'Ton paiement a échoué.',
  elFailModalDesc:
    "Merci d'essayer avec une nouvelle carte ou de réessayer ultérieurement.",
  addNewCard: 'Ajouter nouvelle carte',
  elWarningModalTitle:
    "Nous n'avons pas les informations de ta carte de paiement.",
  elWarningModalDesc:
    "Merci d'ajouter une carte de paiement à ton compte pour pouvoir acheter des sessions en direct.",
  downloadAppMessageText:
    "Tu as réservé ta première session. Maintenant, télécharge l'application et connecte-toi pour accéder à ton programme personnalisé.",
  downloadAppMessageButton: "Télécharger l'application"
}

export default fr
