import styled from 'styled-components'
import { styledColor } from '../../utils'

export const CoachMenuStyled = styled.div`
  background: ${styledColor('white.100', 'white.08')};
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  overflow: hidden;
`

export const CoachMenuItemStyled = styled.button`
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0.75rem 1rem;
  cursor: pointer;
  width: 100%;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${styledColor('gray.0', 'white.16')};
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
`

export const CoachMenuItemIconStyled = styled.div`
  margin-right: 0.5rem;
  line-height: 0;
`

export const CloseButtonStyled = styled.button`
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 999rem;
  border: none;
  outline: none;
  background-color: ${styledColor('black.08', 'white.08')};
  color: ${styledColor('gray.700', 'white.80')};
  line-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 1rem;
  top: 2rem;
  cursor: pointer;
`
