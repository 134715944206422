import React from 'react'

const Back = () => {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.68296 6.50275L7.06891 11.0967C7.39328 11.4367 7.39328 11.9865 7.06891 12.3229C6.74453 12.6593 6.22001 12.6593 5.89564 12.3229L0.926503 7.11768C0.612483 6.78851 0.605578 6.2604 0.90235 5.92037L5.89219 0.678988C6.05438 0.508978 6.26832 0.425781 6.47882 0.425781C6.68932 0.425781 6.90327 0.508978 7.06546 0.678988C7.38983 1.01901 7.38983 1.56883 7.06546 1.90523L2.68296 6.50275Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default React.memo(Back)
