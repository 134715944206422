const basicColors = {
  success: '#6BD39B',
  warning: '#EB5757',
  royalPruple: '#7643A1',
  purpleHeart: '#6541B7',
  blueViolet: '#4E32B6',
  stPatricksBlue: '#2F2A63',
  oxfordBlue: '#002548',
  sugarCrystal: '#F6F5FF',
  electricBlue: '#64ECFF',
  theLotusBlue: '#3BB4FF',
  mediumAquamarine: '#6BD39B',
  persianPink: '#FF8BD1',
  mustard: '#FFDF45',
  lavenderBlue: '#D2CDFF'
}

const gray = {
  0: '#F6F5FF',
  100: '#DFE0E2',
  200: '#C5C6CA',
  300: '#AAADB2',
  400: '#8F939A',
  500: '#757981',
  600: '#696C74',
  700: '#414348',
  800: '#313336',
  900: '#141414'
}

const white = {
  100: 'rgba(255, 255, 255, 1)',
  80: 'rgba(255, 255, 255, 0.8)',
  72: 'rgba(255, 255, 255, 0.72)',
  64: 'rgba(255, 255, 255, 0.64)',
  48: 'rgba(255, 255, 255, 0.48)',
  40: 'rgba(255, 255, 255, 0.4)',
  32: 'rgba(255, 255, 255, 0.32)',
  24: 'rgba(255, 255, 255, 0.24)',
  16: 'rgba(255, 255, 255, 0.16)',
  '08': 'rgba(255, 255, 255, 0.08)'
}

const black = {
  100: 'rgba(0, 0, 0, 1)',
  80: 'rgba(0, 0, 0, 0.8)',
  72: 'rgba(0, 0, 0, 0.72)',
  64: 'rgba(0, 0, 0, 0.64)',
  48: 'rgba(0, 0, 0, 0.48)',
  40: 'rgba(0, 0, 0, 0.4)',
  32: 'rgba(0, 0, 0, 0.32)',
  24: 'rgba(0, 0, 0, 0.24)',
  16: 'rgba(0, 0, 0, 0.16)',
  '08': 'rgba(0, 0, 0, 0.08)'
}

const mainColors = {
  primary: {
    light: {
      100: '#C19FEC',
      200: '#9F70DA'
    },
    main: '#834CC9',
    dark: {
      100: '#6B2EB8',
      200: '#500FA2'
    }
  },
  secondary: {
    light: {
      100: '#7FA9F6',
      200: '#548DF5'
    },
    main: '#2C74F4',
    dark: {
      100: '#0F5FF0',
      200: '#0547BE'
    }
  }
}

const colors = {
  gray,
  white,
  black,
  ...basicColors,
  ...mainColors
}

export default colors
