import de from './de'
import en from './en'
import es from './es'
import fr from './fr'
import it from './it'
import ja from './ja'
import pt from './pt'
import ru from './ru'
import tr from './tr'

const languages = { en, fr, it, tr, de, es, ja, pt, ru }

export default languages
