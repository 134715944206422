import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { PlusVideoIcon } from '../../icons'
import Box from '../Box'
import {
  ButtonStyled,
  IconContainerStyled,
  LiveSessionBoxStyled
} from './styled'
import { useLiveSession } from '../../hooks'
import { useTranslation } from '../../contexts'
import AlertModal from '../AlertModal'
import {
  buyExtraLiveSession,
  getExtraLiveCampaign,
  buyExtraLiveSessionWithPaymentIntent
} from '../../api'
import { incrementCredit, useChatSlice } from '../../store'
import LoaderWithBackdrop from '../LoaderWithBackdrop'
import { useDispatch } from 'react-redux'
import AdditionPaymentFormModal from './AdditionPaymentFormModal'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const stripePromise = loadStripe('pk_live_QVbEMdomUuJrSeAxCxIOr3kV000bEPj815')

const ExtraLiveSessionBox = () => {
  const dispatch = useDispatch()
  const { token, user, countryCode } = useChatSlice()
  const { isLiveSessionUser, type } = useLiveSession()
  const { t, lang } = useTranslation()
  const [clientSecret, setClientSecret] = useState('')
  const [isPriceModalActive, setPriceModal] = useState(false)
  const [isSuccessModalActive, setIsSuccessModalActive] = useState(false)
  const [isFailModalActive, setIsFailModalActive] = useState(false)
  const [cardLast4Digits, setCardLast4Digits] = useState('')
  const [isBuyingPending, setIsBuyingPending] = useState(false)
  const [priceObject, setPriceObject] = useState({
    isInitialized: false,
    currency: '',
    price: '',
    priceId: ''
  })
  const initializeExtraLiveCampaign = useCallback(async () => {
    try {
      const query = user.isTherapyUser
        ? `?filter=therapy_addition&lang=${lang}&country=${countryCode}`
        : ''
      const { data } = await getExtraLiveCampaign({ token, query })
      if (data.status) {
        setCardLast4Digits(data.data.card_last4)
        const {
          currency,
          price,
          price_id: priceId
        } = data.data.campaigns[0].prices[0]
        setPriceObject({ currency, price, priceId, isInitialized: true })
      }
    } catch (error) {
      console.error(error)
      setPriceObject({
        isInitialized: true,
        currency: '',
        price: '',
        priceId: ''
      })
    }
  }, [token, user, countryCode, lang])

  useEffect(() => {
    initializeExtraLiveCampaign()
  }, [token, initializeExtraLiveCampaign])

  const handleBuyExtraLiveSession = async () => {
    setIsBuyingPending(true)

    try {
      const { data } = await buyExtraLiveSession({
        token,
        priceId: priceObject.priceId
      })

      if (data.status) {
        if (data.requiresAction) {
          setClientSecret(data.clientSecret)
          return
        }

        setIsSuccessModalActive(true)
        dispatch(incrementCredit())
        return
      }

      setIsFailModalActive(true)
    } catch (error) {
      console.error(error)
      setIsFailModalActive(true)
    } finally {
      setIsBuyingPending(false)
      setPriceModal(false)
    }
  }

  const handlePaymentFormSuccess = async (paymentIntent) => {
    try {
      const result = await buyExtraLiveSessionWithPaymentIntent({
        token,
        priceId: priceObject.priceId,
        paymentIntent
      })

      if (result.status && !result.data.requiresAction) {
        setIsSuccessModalActive(true)
        dispatch(incrementCredit())
        setClientSecret('')
        return
      }

      setIsFailModalActive(true)
    } catch (error) {}
  }

  if (!isLiveSessionUser || type === 1) return null

  return (
    <Fragment>
      {Boolean(clientSecret) && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            locale: lang
          }}>
          <AdditionPaymentFormModal
            close={() => setClientSecret('')}
            onSuccess={handlePaymentFormSuccess}
          />
        </Elements>
      )}

      <LiveSessionBoxStyled>
        <IconContainerStyled>
          <PlusVideoIcon />
        </IconContainerStyled>

        <Box flex="1">
          <Box fontWeight="500" mb="0.25rem">
            {t('extraLiveBoxTitle')}
          </Box>
          <Box
            mb="1rem"
            color="gray.600"
            fontSize="0.875rem"
            lineHeight="1.125rem">
            {t('extraLiveBoxDesc')}
          </Box>

          <ButtonStyled onClick={() => setPriceModal(true)}>
            {t('extraLiveBoxButton')}
          </ButtonStyled>
        </Box>
      </LiveSessionBoxStyled>

      <LoaderWithBackdrop
        loading={isPriceModalActive && !priceObject.isInitialized}
      />

      <AlertModal
        isActive={isPriceModalActive && priceObject.isInitialized}
        close={() => setPriceModal(false)}
        title={t('elPriceModalTitle')}
        description={t('elPriceModalDesc')(
          priceObject.price,
          (priceObject.currency || '').toLocaleUpperCase(),
          cardLast4Digits
        )}
        buttonText={t('continue')}
        isButtonDisabled={isBuyingPending}
        onButtonClick={handleBuyExtraLiveSession}
      />

      <AlertModal
        isActive={isSuccessModalActive}
        close={() => setIsSuccessModalActive(false)}
        title={t('elSuccessModalTitle')}
        description={t('elSuccessModalDesc')}
        buttonText={t('continue')}
        onButtonClick={() => setIsSuccessModalActive(false)}
        type="success"
      />

      <AlertModal
        isActive={isFailModalActive}
        close={() => setIsFailModalActive(false)}
        title={t('elFailModalTitle')}
        description={t('elFailModalDesc')}
        type="fail"
        cancelButton
      />

      <AlertModal
        isActive={
          isPriceModalActive && priceObject.isInitialized && !priceObject.price
        }
        close={() => setPriceModal(false)}
        title={t('elWarningModalTitle')}
        description={t('elWarningModalDesc')}
        type="fail"
        cancelButton
      />
    </Fragment>
  )
}

export default ExtraLiveSessionBox
