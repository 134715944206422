import React from 'react'

const Menu = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2174 4.56534H3.78261C3.35039 4.56534 3 4.91572 3 5.34795V6.13056C3 6.56278 3.35039 6.91316 3.78261 6.91316H20.2174C20.6496 6.91316 21 6.56278 21 6.13056V5.34795C21 4.91572 20.6496 4.56534 20.2174 4.56534Z"
        fill="currentColor"
      />
      <path
        d="M20.2174 10.8256H3.78261C3.35039 10.8256 3 11.176 3 11.6082V12.3908C3 12.823 3.35039 13.1734 3.78261 13.1734H20.2174C20.6496 13.1734 21 12.823 21 12.3908V11.6082C21 11.176 20.6496 10.8256 20.2174 10.8256Z"
        fill="currentColor"
      />
      <path
        d="M20.2174 17.087H3.78261C3.35039 17.087 3 17.4374 3 17.8696V18.6523C3 19.0845 3.35039 19.4349 3.78261 19.4349H20.2174C20.6496 19.4349 21 19.0845 21 18.6523V17.8696C21 17.4374 20.6496 17.087 20.2174 17.087Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default React.memo(Menu)
