import styled from 'styled-components'
import { styledColor } from '../../utils'

export const IconButtonStyled = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 999rem;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  color: ${styledColor('gray.500', 'white.100')};
  border: 1.5px solid ${styledColor('gray.0', 'white.16')};
  background-color: transparent;

  &:not(:disabled) {
    cursor: pointer;
  }
`
