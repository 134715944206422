import React from 'react'
import { useTranslation } from '../../contexts'
import { CheckIcon, CloseIcon } from '../../icons'
import {
  BackdropStyled,
  ButtonsContainer,
  CancelButton,
  ContainerStyled,
  DescriptionStyled,
  IconContainer,
  PrimaryButton,
  TitleStyled
} from './styled'

const AlertModal = ({
  isActive,
  close,
  title,
  description,
  cancelButton,
  buttonText,
  isButtonDisabled,
  onButtonClick,
  type
}) => {
  const { t } = useTranslation()
  if (!isActive) return null

  return (
    <BackdropStyled onClick={close}>
      <ContainerStyled onClick={(e) => e.stopPropagation()}>
        {Boolean(type) && (
          <IconContainer type={type}>
            {type === 'success' ? (
              <CheckIcon size="2rem" />
            ) : (
              <CloseIcon size="1.75rem" />
            )}
          </IconContainer>
        )}

        <TitleStyled>{title}</TitleStyled>
        <DescriptionStyled>{description}</DescriptionStyled>

        <ButtonsContainer>
          {Boolean(buttonText) && (
            <PrimaryButton onClick={onButtonClick} disabled={isButtonDisabled}>
              {buttonText}
            </PrimaryButton>
          )}
          {cancelButton && (
            <CancelButton onClick={close} centered={!buttonText}>
              {t('cancel')}
            </CancelButton>
          )}
        </ButtonsContainer>
      </ContainerStyled>
    </BackdropStyled>
  )
}

export default AlertModal
