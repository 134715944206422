import React from 'react'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from '../../hooks'
import { sendMessage } from '../../api'
import { useTranslation } from '../../contexts'
import { SendIcon } from '../../icons'
import { addNewMessage, useChatSlice } from '../../store'
import { scrollToBottom } from '../../utils'
import {
  NewMessageFieldContainerStyled,
  NewMessageFieldWrapperStyled,
  SendButtonStyled,
  TextAreaStyled
} from './styled'

const NewMessageField = () => {
  const dispatch = useDispatch()
  const { token } = useChatSlice()
  const { t, lang } = useTranslation()
  const [text, setText] = React.useState('')
  const [isFocused, setIsFocused] = React.useState(false)
  const isTabOrPhone = useMediaQuery('(max-width: 992px)')

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!text) return

    dispatch(
      addNewMessage({
        newMessage: {
          datetime: new Date().getTime().toString(),
          sender: 2,
          name: '',
          avatar: '',
          isread: 0,
          message: text
        }
      })
    )
    scrollToBottom()
    sendMessage({ token, message: text, lang })
    setText('')
  }

  return (
    <NewMessageFieldWrapperStyled>
      <NewMessageFieldContainerStyled
        onSubmit={handleSubmit}
        isFocused={isFocused && isTabOrPhone}>
        <TextAreaStyled
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder={t('sendPlaceholder')}
          maxRows={8}
          maxLength={2000}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        <SendButtonStyled
          type="submit"
          isActive={Boolean(text)}
          disabled={!Boolean(text)}>
          <SendIcon />
        </SendButtonStyled>
      </NewMessageFieldContainerStyled>
    </NewMessageFieldWrapperStyled>
  )
}

export default NewMessageField
