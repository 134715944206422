import React from 'react'
import { getUrlQuery } from '../../utils'
import { useChatSlice } from '../../store'
import { MessagesListWrapperStyled } from './styled'
import { getDailyStructuredMessages } from './utils'
import MessageListByDates from './MessageListByDates'

const MessagesList = () => {
  const showDownload = React.useRef(
    getUrlQuery('showDownload', '0') === '1'
  ).current
  const { messages } = useChatSlice()
  const structuredMessages = React.useMemo(
    () => getDailyStructuredMessages(messages),
    [messages]
  )

  return (
    <MessagesListWrapperStyled id="messagesList" downloadActive={showDownload}>
      {structuredMessages.map((item) => (
        <MessageListByDates key={item.date} {...item} />
      ))}
    </MessagesListWrapperStyled>
  )
}

export default MessagesList
