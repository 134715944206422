const ru = {
  sendPlaceholder: 'Введите ваше сообщение',
  messagesCouldntLoad:
    'Мы не смогли загрузить ваши сообщения на данный момент.',
  tryAgain: 'Повторить попытку',
  errorCode: 'Код ошибки',
  downloadApp: 'Скачайте приложение для получения уникального опыта',
  download: 'Скачать',
  bookVideoCall: 'Забронировать сессию',
  faqTitle: 'Часто задаваемые вопросы',
  faqShort: 'FAQ',
  liveSessionMessage:
    'Вам доступна онлайн сессия. Нажмите здесь, чтобы забронировать время для встречи с психологом.',
  liveSessionMessageTherapy:
    'Вам доступна онлайн сессия. Нажмите здесь, чтобы забронировать время для встречи с психологом.',
  bookLiveSession: 'Забронировать онлайн сессию',
  liveBoxTitle: 'Онлайн сессии',
  liveBoxDesc: 'Давайте забронируем сессию',
  extraLiveBoxTitle: 'Нужны дополнительные сессии?',
  extraLiveBoxDesc: 'Будем рады помочь!',
  extraLiveBoxButton: 'Купить онлайн сессию',
  elPriceModalTitle: 'Вы покупаете дополнительную онлайн сессию.',
  elPriceModalDesc: (price, currency, cardNumber) =>
    `Платеж в размере ${price} ${currency} будет снят с вашей карты, оканчивающейся на ${cardNumber}.`,
  continue: 'Продолжить',
  cancel: 'Отменить',
  elSuccessModalTitle: 'Поздравляем!',
  elSuccessModalDesc:
    'Вам доступна новая сессия. Теперь вы можете записаться на онлайн сессию.',
  elFailModalTitle: 'Ваш платеж не прошел.',
  elFailModalDesc: 'Добавьте другую карту или повторите попытку позже.',
  addNewCard: 'Добавить другую карту',
  elWarningModalTitle: 'У нас нет информации о вашей карте.',
  elWarningModalDesc:
    'Пожалуйста, добавьте данные вашей карты в свой аккаунт для покупки онлайн сессий.',
  downloadAppMessageText:
    'Вы забронировали свой первый сеанс. Теперь скачайте приложение и войдите в систему, чтобы получить доступ к индивидуальной программе.',
  downloadAppMessageButton: 'Скачать приложение'
}

export default ru
