import React from 'react'
import { LiveSessionIcon } from '../../icons'
import Box from '../Box'
import Button from '../Button'
import { IconContainerStyled, LiveSessionBoxStyled } from './styled'
import { useLiveSession } from '../../hooks'
import { useTranslation } from '../../contexts'

const LiveSessionBox = () => {
  const {
    isLiveSessionUser,
    isLiveSessionAvailable,
    remainingCreditsText,
    openCalendlyWidget
  } = useLiveSession()
  const { t } = useTranslation()

  if (!isLiveSessionUser) return null

  return (
    <LiveSessionBoxStyled>
      <IconContainerStyled>
        <LiveSessionIcon />
      </IconContainerStyled>

      <Box flex="1">
        <Box fontWeight="500" mb="0.25rem">
          {t('liveBoxTitle')}
        </Box>
        <Box
          mb="1rem"
          color="gray.600"
          _dark={{ color: 'gray.400' }}
          fontSize="0.875rem"
          lineHeight="1.125rem">
          {t('liveBoxDesc')}
        </Box>

        <Button
          colorScheme="purple"
          disabled={!isLiveSessionAvailable}
          onClick={openCalendlyWidget}>
          {t('bookLiveSession')} {remainingCreditsText}
        </Button>
      </Box>
    </LiveSessionBoxStyled>
  )
}

export default LiveSessionBox
