import React from 'react'
import { useTheme } from 'styled-components'

export function themedComponent(Component) {
  const GeneratedComponent = (props) => {
    let themeSpesificProps = {}
    const { mode } = useTheme()

    if (mode === 'dark') themeSpesificProps = props._dark
    return <Component {...props} {...themeSpesificProps} />
  }

  return GeneratedComponent
}
