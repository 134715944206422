export const checkIfIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(window.navigator.platform) ||
    (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const checkIfAndroid = () => {
  return window.navigator.userAgent.toLowerCase().indexOf('android') > -1
}

export const checkIfMacOS = () => {
  return window.navigator.platform.toLowerCase().indexOf('mac') > -1
}

export const checkIfWindows = () => {
  return (
    ['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(window.navigator.platform) >
    -1
  )
}

export const checkIfLinux = () => {
  return /Linux/.test(window.navigator.platform)
}

export const getOperatingSystem = () => {
  if (checkIfIOS()) return 'iOS'
  if (checkIfAndroid()) return 'Android'
  if (checkIfWindows()) return 'Windows'
  if (checkIfMacOS()) return 'MacOS'
  if (checkIfLinux()) return 'Linux'

  return 'Unknown'
}
