import React from 'react'
import { useTranslation } from '../../contexts'
import { getUrlQuery } from '../../utils'
import { DownloadBannerStyled } from './styled'

const DownloadBanner = () => {
  const { t } = useTranslation()
  const showDownload = React.useRef(
    getUrlQuery('showDownload', '0') === '1'
  ).current

  if (!showDownload) return null

  return (
    <DownloadBannerStyled>
      <span>{t('downloadApp')}</span>
      <button onClick={window.downloadHandler}>{t('download')}</button>
    </DownloadBannerStyled>
  )
}

export default DownloadBanner
