const it = {
  sendPlaceholder: 'Scrivi il tuo messaggio',
  messagesCouldntLoad: 'Non siamo riusciti a caricare il tuo messaggio.',
  tryAgain: 'Riprova',
  errorCode: 'Codice errore',
  downloadApp: "Scarica la app per un'esperienza ottimizzata",
  download: 'Scarica',
  bookVideoCall: 'Prenota una seduta',
  faqTitle: 'Domande frequenti',
  faqShort: 'FAQ',
  liveSessionMessage:
    "Hai una seduta live disponibile. Clicca per prenotare l'orario a cui desideri incontrare il tuo psicologo.",
  liveSessionMessageTherapy:
    "Hai una seduta live disponibile. Clicca per prenotare l'orario a cui desideri incontrare il tuo psicologo.",
  bookLiveSession: 'Prenota una seduta dal vivo',
  liveBoxTitle: 'Sedute live',
  liveBoxDesc: 'Prenota la tua seduta',
  extraLiveBoxTitle: 'Ti servono più sedute?',
  extraLiveBoxDesc: 'Ti aiutiamo noi!',
  extraLiveBoxButton: 'Acquista una seduta live',
  elPriceModalTitle: 'Stai acquistando una seduta live aggiuntiva.',
  elPriceModalDesc: (price, currency, cardNumber) =>
    `Preleveremo un importo di ${price} ${currency} dalla tua carta di credito con ultime tre cifre ${cardNumber}.`,
  continue: 'Continua',
  cancel: 'Annulla',
  elSuccessModalTitle: 'Congratulazioni!',
  elSuccessModalDesc:
    'Hai un credito per una nuova seduta. Ora puoi prenotare la tua seduta live.',
  elFailModalTitle: 'Il tuo pagamento non è andato a buon fine.',
  elFailModalDesc: 'Aggiungi una nuova carta o riprova più tardi.',
  addNewCard: 'Aggiungi una nuova carta',
  elWarningModalTitle: 'Non abbiamo i dati della tua carta di credito.',
  elWarningModalDesc:
    'Aggiungi una carta di credito al tuo account per acquistare sedute dal vivo.',
  downloadAppMessageText:
    'Hai prenotato la tua prima seduta. Ora scarica la app e accedi per ottenere il tuo programma personalizzato',
  downloadAppMessageButton: 'Scarica la app'
}

export default it
