import React from 'react'
import { useTranslation } from '../../contexts'
import Box from '../Box'
import MessageGroup from './MessageGroup'
import { getMessagesDateTitle } from './utils'

export default function MessageListByDates({ date, messages }) {
  const { translationLang } = useTranslation()

  return (
    <Box width="100%">
      <Box
        textAlign="center"
        fontSize={12}
        lineHelight={14}
        color="gray.400"
        mb="1rem"
        mt="2rem">
        {getMessagesDateTitle(date, translationLang)}
      </Box>

      <Box>
        {messages.map((group) => (
          <MessageGroup key={`${group.sender}-${group.datetime}`} {...group} />
        ))}
      </Box>
    </Box>
  )
}
