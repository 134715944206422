import React from 'react'

const ArrowRight = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2943 8.84552C10.3151 8.86551 10.3316 8.88948 10.3428 8.91599C10.3541 8.9425 10.3599 8.971 10.3599 8.99981C10.3599 9.02861 10.3541 9.05712 10.3428 9.08363C10.3316 9.11014 10.3151 9.13411 10.2943 9.15409L5.91432 13.5341C5.83398 13.6138 5.77021 13.7086 5.7267 13.813C5.68318 13.9175 5.66078 14.0295 5.66078 14.1427C5.66078 14.2558 5.68318 14.3679 5.7267 14.4723C5.77021 14.5768 5.83398 14.6716 5.91432 14.7512C6.07492 14.9109 6.29216 15.0005 6.51861 15.0005C6.74505 15.0005 6.9623 14.9109 7.12289 14.7512L11.9657 9.90838C12.2044 9.66621 12.3383 9.33984 12.3383 8.99981C12.3383 8.65978 12.2044 8.3334 11.9657 8.09124L7.12289 3.24838C6.9623 3.08874 6.74505 2.99913 6.51861 2.99913C6.29216 2.99913 6.07492 3.08874 5.91432 3.24838C5.83398 3.32806 5.77021 3.42286 5.7267 3.52731C5.68318 3.63177 5.66078 3.7438 5.66078 3.85695C5.66078 3.97011 5.68318 4.08214 5.7267 4.18659C5.77021 4.29104 5.83398 4.38584 5.91432 4.46552L10.2943 8.84552Z"
      fill="currentColor"
    />
  </svg>
)
export default React.memo(ArrowRight)
