const ja = {
  sendPlaceholder: 'メッセージを送信',
  messagesCouldntLoad: 'メッセージを読み取ることができませんでした。',
  tryAgain: 'もう一度試す',
  errorCode: 'エラーコード',
  downloadApp: 'アプリをダウンロードして、効果を体感してみましょう',
  download: 'ダウンロード',
  bookVideoCall: 'セッションを予約する',
  faqTitle: 'よくある質問',
  faqShort: 'FAQ',
  liveSessionMessage:
    '予約可能なセッションがあります。心理士と対面する日時を予約しましょう。',
  liveSessionMessageTherapy:
    '予約可能なセッションがあります。心理士と対面する日時を予約しましょう。',
  bookLiveSession: 'ライブセッションを予約する',
  liveBoxTitle: 'ライブセッション',
  liveBoxDesc: 'セッションを予約しましょう',
  extraLiveBoxTitle: '追加のセッションが必要ですか？',
  extraLiveBoxDesc: 'お役に立てたら嬉しいです！',
  extraLiveBoxButton: 'ライブセッションを購入する',
  elPriceModalTitle: '追加のライブセッションを購入しています',
  elPriceModalDesc: (price, currency, cardNumber) =>
    `${price} ${currency} のお支払いが実行され、お客様の数字${cardNumber}で終わるクレジットカードから引き落としされます。`,
  continue: '続ける',
  cancel: 'キャンセル',
  elSuccessModalTitle: 'おめでとうございます!',
  elSuccessModalDesc:
    '新しいセッションが利用可能です。 ライブセッションを予約してみましょう。',
  elFailModalTitle: 'お支払いが失敗しました。',
  elFailModalDesc:
    '新しいカードを入力するか、しばらく経ってからやり直してください。',
  addNewCard: '新しいカードを入力する',
  elWarningModalTitle: 'カード情報が入力されていません。',
  elWarningModalDesc:
    'アカウントにカード情報を入力し、ライブセッションをご購入ください。',
  downloadAppMessageText:
    '最初のセッションを予約しました。アプリをダウンロードしてサインインし、パーソナライズされたプログラムを利用しましょう。',
  downloadAppMessageButton: 'アプリをダウンロード'
}

export default ja
