import React from 'react'
import { IconBox } from '..'
import Box from '../Box'
import { BrandIcon } from '../../icons'
import { ButtonStyled, DownloadAppMessageStyled } from './styled'
import ArrowRight from '../../icons/ArrowRight'
import { useTranslation } from '../../contexts'

const DownloadAppMessage = () => {
  const { t } = useTranslation()

  return (
    <DownloadAppMessageStyled>
      <IconBox color="primary.main" mb="0.5rem">
        <BrandIcon />
      </IconBox>

      <Box
        color="gray.500"
        _dark={{ color: 'gray.400' }}
        mb="1rem"
        fontSize="0.875rem"
        lineHeight="1.125rem">
        {t('downloadAppMessageText')}
      </Box>

      <ButtonStyled onClick={window.downloadHandler}>
        {t('downloadAppMessageButton')}{' '}
        <IconBox ml="0.75rem">
          <ArrowRight />
        </IconBox>
      </ButtonStyled>
    </DownloadAppMessageStyled>
  )
}

export default DownloadAppMessage
