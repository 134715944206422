import styled from 'styled-components'
import { styledColor } from '../../utils'

export const BackdropStyled = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${styledColor('black.64')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
`
