import styled from 'styled-components'
import {
  space,
  layout,
  typography,
  flexbox,
  color,
  border,
  opacity,
  position
} from 'styled-system'
import { themedComponent } from '../../utils'

export const BoxStyled = styled('div')(
  space,
  layout,
  typography,
  flexbox,
  color,
  border,
  opacity,
  position
)

export const ThemedBox = themedComponent(BoxStyled)

export const IconBoxStyled = styled(ThemedBox)``

IconBoxStyled.defaultProps = {
  lineHeight: 0
}
