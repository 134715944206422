import styled, { css } from 'styled-components'
import { ifProp, switchProp } from 'styled-tools'
import { styledColor } from '../../utils'

export const MessagesListWrapperStyled = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: ${ifProp('downloadActive', '8rem', '5.5rem')};
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 8rem;
`

export const MessageListItemStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${switchProp('sender', {
    user: 'align-items: flex-end;',
    expert: 'align-items: flex-start;',
    system: 'align-items: center;'
  })}

  &:not(:last-child) {
    margin-bottom: 0.25rem;
  }
`

export const MessageBoxStyled = styled.div`
  width: fit-content;
  max-width: 90%;
  padding: 0.75rem 1rem;
  position: relative;
  border-radius: 1rem;
  ${switchProp('sender', {
    user: css`
      background-color: ${styledColor('primary.main', 'secondary.main')};
      color: ${styledColor('white.100')};
    `,
    expert: css`
      color: ${styledColor('gray.800', 'white.100')};
      background: ${styledColor('gray.0', 'white.24')};
    `,
    system: css`
      padding: 0;
      margin: 2rem 3rem;
      text-align: center;
    `
  })}
  ${({ index, messagesCount, sender }) => {
    if (sender === 'system') return ''
    const radiusDirection = sender === 'user' ? 'right' : 'left'
    if (messagesCount === 1)
      return `border-top-${radiusDirection}-radius: 0.5rem;`
    if (messagesCount === 2)
      return index === 0
        ? `border-bottom-${radiusDirection}-radius: 0.5rem;`
        : `border-top-${radiusDirection}-radius: 0.5rem;`
    return index === 0
      ? `border-bottom-${radiusDirection}-radius: 0.5rem;`
      : index === messagesCount - 1
      ? `border-top-${radiusDirection}-radius: 0.5rem;`
      : `border-bottom-${radiusDirection}-radius: 0.5rem; border-top-${radiusDirection}-radius: 0.5rem;`
  }}
`

export const DateStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${styledColor('gray.400')};
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
`

export const ExpertNameStyled = styled.div`
  color: #2c74f4;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
`

export const MessageStyled = styled.div`
  font-size: 0.85rem;
  line-height: 1.5;
`

export const ExpertAvatarStyled = styled.img`
  position: absolute;
  width: 2.6rem;
  height: 2.6rem;
  left: 0;
  top: 0;
  z-index: 2;
  border-radius: 999rem;
  transform: translate(-50%, -50%);
`
