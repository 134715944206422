import React from 'react'

const Close = ({ size = '12' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.43695 6.14902C7.39615 6.10497 7.3735 6.0472 7.3735 5.98723C7.3735 5.92725 7.39615 5.86948 7.43695 5.82543L11.7056 1.56775C11.7924 1.48363 11.8613 1.38302 11.9084 1.27186C11.9555 1.1607 11.9797 1.04125 11.9797 0.920581C11.9797 0.79991 11.9555 0.680463 11.9084 0.569305C11.8613 0.458146 11.7924 0.357532 11.7056 0.273413C11.6213 0.186894 11.5204 0.11812 11.409 0.0711581C11.2975 0.0241963 11.1778 0 11.0568 0C10.9358 0 10.8161 0.0241963 10.7046 0.0711581C10.5932 0.11812 10.4923 0.186894 10.408 0.273413L6.13928 4.5311C6.09511 4.57179 6.03719 4.59439 5.97707 4.59439C5.91694 4.59439 5.85902 4.57179 5.81486 4.5311L1.54618 0.273413C1.37345 0.109843 1.14302 0.0207926 0.904891 0.0255749C0.666756 0.0303572 0.440113 0.128587 0.274119 0.298959C0.187376 0.383078 0.118425 0.483692 0.0713418 0.594851C0.0242587 0.706009 0 0.825456 0 0.946127C0 1.0668 0.0242587 1.18625 0.0713418 1.2974C0.118425 1.40856 0.187376 1.50918 0.274119 1.5933L4.54279 5.85098C4.58359 5.89503 4.60625 5.9528 4.60625 6.01277C4.60625 6.07274 4.58359 6.13051 4.54279 6.17456L0.274119 10.4322C0.187376 10.5164 0.118425 10.617 0.0713418 10.7281C0.0242587 10.8393 0 10.9587 0 11.0794C0 11.2001 0.0242587 11.3195 0.0713418 11.4307C0.118425 11.5419 0.187376 11.6425 0.274119 11.7266C0.358455 11.8131 0.459329 11.8819 0.570774 11.9288C0.682219 11.9758 0.801974 12 0.922957 12C1.04394 12 1.1637 11.9758 1.27514 11.9288C1.38659 11.8819 1.48746 11.8131 1.5718 11.7266L5.84047 7.4689C5.88464 7.42821 5.94255 7.40561 6.00268 7.40561C6.06281 7.40561 6.12073 7.42821 6.16489 7.4689L10.4336 11.7266C10.6056 11.8982 10.839 11.9947 11.0824 11.9947C11.3258 11.9947 11.5592 11.8982 11.7312 11.7266C11.9033 11.5549 12 11.3222 12 11.0794C12 10.8367 11.9033 10.6039 11.7312 10.4322L7.43695 6.14902Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default React.memo(Close)
