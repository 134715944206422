import { v4 } from 'uuid'

export const scrollToBottom = ({ delay = 0, behavior = 'auto' } = {}) => {
  setTimeout(
    () => window.scrollTo({ top: document.body.scrollHeight, behavior }),
    delay
  )
}

export const immediatelySetInterval = (callback, delay) => {
  callback()
  return setInterval(callback, delay)
}

export const withUniqueId = (array) =>
  array.map((item) => ({ ...item, uniqueId: v4() }))

export const isTutorialSeenBefore = () => {
  if (!localStorage) return true

  const isTutorialSeen = localStorage.getItem('TUTORIAL_SEEN_BEFORE')

  if (isTutorialSeen) return JSON.parse(isTutorialSeen)

  return false
}
