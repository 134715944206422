import styled from 'styled-components'
import { styledColor } from '../../utils'

export const BackdropStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  transition: 0.4s ease-in-out;
  animation: backdrop-anim 0.4s ease-in-out forwards;

  @keyframes backdrop-anim {
    from {
      background-color: #0000;
    }

    to {
      background-color: #0004;
    }
  }
`

export const ModalStyled = styled.div`
  height: 90vh;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  background-color: ${styledColor('white.100', 'gray.800')};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  transition: 0.4s ease-in-out;
  box-shadow: 0px -6px 24px rgba(0, 0, 0, 0.25);
  animation: modal-anim 0.4s ease-in-out forwards;

  @keyframes modal-anim {
    from {
      transform: translateY(105%);
    }

    to {
      transform: translateY(0);
    }
  }
`
