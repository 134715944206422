import styled from 'styled-components'
import { styledColor } from '../../utils'

export const ColorfulBackgroundStyled = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: white;
  background: url(https://www.datocms-assets.com/42048/1656603266-colorful-background-min.png),
    ${styledColor('white.100', 'black.100')};
  background-size: cover;
  background-position: top center;
  animation: fadein 0.4s ease-in-out forwards;

  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`
