import axios from 'axios'

const auth = { username: 'meditasyon', password: '1098' }

export default axios.create({
  baseURL: 'https://apilab.meditopia.com/chat',
  auth
})

export const paymentService = axios.create({
  baseURL: 'https://mpay.meditopia.com/api'
})

export const v4Service = axios.create({
  baseURL: 'https://api.meditopia.com/api/v4'
})

export const countryService = axios.create({
  baseURL: 'https://paygate.meditopia.com'
})
