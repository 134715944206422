import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { getLiveSessionCreditsText, getUrlQuery } from '../../utils'

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    token: window.USER_TOKEN || '',
    platform: getUrlQuery('platform', 'app'),
    activeScreen: 'chat',
    expert: {
      avatar: '',
      description: '',
      title: '',
      name: ''
    },
    liveSession: {
      isLiveSessionUser: 0,
      isLiveSessionAvailable: false,
      initialCredits: 0,
      currentCredits: 0,
      totalCredits: 0,
      liveSessionLink: '',
      remainingCreditsText: '',
      isLiveSessionInitialized: false,
      type: 0
    },
    messages: [],
    isChatInitialized: false,
    liveSessionMessagePreviousId: 0,
    countryCode: '',
    user: null
  },
  reducers: {
    setLiveSession: (state, action) => {
      const { status, credit, totalcredit, link, type } =
        action.payload.liveSession
      state.liveSession = {
        isLiveSessionUser: status > 0,
        isLiveSessionAvailable: credit > 0,
        initialCredits: state.liveSession.isLiveSessionInitialized
          ? state.liveSession.initialCredits
          : credit,
        currentCredits: credit,
        totalCredits: totalcredit,
        liveSessionLink: link,
        isLiveSessionInitialized: true,
        remainingCreditsText: getLiveSessionCreditsText({
          currentCredits: credit,
          totalCredits: totalcredit
        }),
        type
      }
    },
    setMessages: (state, action) => {
      state.messages = action.payload.messages
    },
    decrementCredit: (state) => {
      const { currentCredits, totalCredits } = state.liveSession
      const credit = currentCredits - 1

      state.liveSession = {
        ...state.liveSession,
        isLiveSessionAvailable: credit > 0,
        currentCredits: credit,
        remainingCreditsText: getLiveSessionCreditsText({
          currentCredits: credit,
          totalCredits: totalCredits
        })
      }
    },
    incrementCredit: (state) => {
      const { currentCredits, totalCredits } = state.liveSession
      const credit = currentCredits + 1
      const maxCredit = totalCredits + 1

      state.liveSession = {
        ...state.liveSession,
        isLiveSessionAvailable: credit > 0,
        currentCredits: credit,
        remainingCreditsText: getLiveSessionCreditsText({
          currentCredits: credit,
          totalCredits: maxCredit
        })
      }
    },
    initializeChat: (state, action) => {
      window.onChatInitialized && window.onChatInitialized()
      state.user = action.payload.user
      state.countryCode = action.payload.countryCode
      state.expert = action.payload.expert
      state.liveSessionMessagePreviousId =
        action.payload.messages[action.payload.messages.length - 1].id
      state.isChatInitialized = true
    },
    setActiveScreen: (state, action) => {
      state.activeScreen = action.payload.activeScreen
    },
    addNewMessage: (state, action) => {
      state.messages.push(action.payload.newMessage)
    }
  }
})

export const {
  setLiveSession,
  setMessages,
  initializeChat,
  setActiveScreen,
  addNewMessage,
  decrementCredit,
  incrementCredit
} = chatSlice.actions

export const useChatSlice = () => useSelector((state) => state.chat)

export default chatSlice
