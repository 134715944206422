import { withUniqueId } from '../../utils'

export const FAQ_DATA = {
  tr: {
    regular: withUniqueId([
      {
        title: 'İlk günümde beni neler bekliyor?',
        text: `Öncelikle başlamak için: Uygulamayı indir, koçunla iletişim kur, canlı başlangıç görüşmeni planla ve ilk günün aktivitelerini gözden geçir.<br/><br/>
        Koçun çevrim içi olduğu ilk zaman aralığında, 1 ila 3 saat içinde sana ulaşacak. Koçunun seninle iletişime geçmesini beklemeden ona mesaj göndererek sohbetinizi başlatabilirsin!<br/><br/>
        Koçunla ilk sohbetinizde birbirinizi tanıyacak, isteklerin, hedeflerin, yaşadığın zorluklar ve bu programda neler aradığın üzerine konuşacaksınız. Koçun seni daha iyi anlamak için sana sorular soracak ve hedeflerin doğrultusunda birlikte nasıl çalışacağınızı açıklayacak.<br/><br/>
        Kişisel programını, günlük içeriklerini içeren rehberini ve koçuna erişebileceğin pencereyi uygulamanın ana sayfasında görebilirsin. Bu nedenle lütfen uygulamayı indirmeyi unutma. İlk günlerinde günlük rehberinde bu programa başlamana yardımcı olacak bilgilendirici makaleler, kısa bilgiler ve egzersizler gibi bazı ısınma içeriklerine erişebileceksin.
        `
      },
      {
        title: 'Koçumla iletişimim nasıl ilerleyecek?',
        text: `Koçun 09.00-21.00 saatleri arasında çevrim içi olacak. Programa kayıt olmanın ardından koçuna mesaj göndermeye başlayabilir, koçunun çevrim içi olduğu ilk zaman aralığında 1-3 saat içinde sana yazmasını bekleyebilirsin.<br/><br/>
        Günlük etkileşimlerde koçlar genellikle 2-3 saat içinde mesajları yanıtlar. Her koşulda en geç 6 saat içinde bir yanıt almayı bekleyebilirsin. Ayrıca, koçunun çevrim içi olup olmaması fark etmeksizin dilediğin zaman koçuna mesaj gönderebilirsin. Koçun çevrimiçi olduğunda sana geri dönüş yapacaktır.
        `
      },
      {
        title: 'Koç kimdir ve bana nasıl yardımcı olabilir?',
        text: `Koç, öz farkındalık kazanma ve hem kişisel hem de çalışma hayatındaki hedeflerine ulaşma yolculuğunda sana eşlik eden sertifikalı bir profesyoneldir. Koçluk Programı, daha iyi kararlar verip duyguları yönetme ve hedefler belirleyip bunlara ulaşma noktasında sana destek olur. Meditopia’nın kişisel koçları bilinçli farkındalık (mindfulness) alanında uzmandır, dolayısıyla sana kişisel programın aracılığıyla destek olmak için farkındalık araçları ve egzersizleri de sunabilirler.<br/><br/>
        Meditopia’nın kişisel koçları farkındalık ilkelerini kullanarak üyelerimize çalışma yaşamı, ilişkiler, öz farkındalık, kaygı ve stres gibi zorlu duygular ve daha pek çok alanda rehberlik ediyor. Zorlu deneyimlerle ve duygularla başa çıkmak, stres, kaygı, öfke, pişmanlık, yeni alışkanlıklar oluşturmak, kendini daha iyi tanımak, hayaller ve değerleri keşfetmek, daha sağlıklı ilişkiler inşa etmek, öz şefkat ve öz güveni artırmak, sınırlar çizebilmek ve gündelik hayatın zorluklarına çözümler üretmek gibi konular üyelerimizin özel olarak fayda aldıklarını belirttikleri alanlar arasında yer alıyor.
        `
      },
      {
        title: 'Koçumla hangi konular hakkında konuşabilirim?',
        text: `Koçunun sana sağladığı güvenli paylaşım alanında dilediğini paylaşabilirsin. Koçunla üzerine eğilmek istediğin konular yaşam amacından her gün yüzleştiğin sorunlara kadar çeşitlilik gösterebilir. Kısa veya uzun vadeli hedeflerin, duyguların, düşüncelerin, ruh hâlin, ilişkilerin, sorumlulukların ya da genel anlamda hayat hakkında konuşabilirsin.<br/><br/>
        Nereden başlayacağını bilmiyorsan ilk olarak kendini tanıtabilir, o gün nasıl hissettiğinden ya da bu programla hayatında yaratmak istediğin değişikliklerden bahsedebilirsin. Sohbeti başlatmak için başvurabileceğin birkaç örneği aşağıda bulabilirsin:<br/><br/>
        Hedefime ulaşmak konusunda yardıma ihtiyacım var.<br/><br/>
        Kendimi motivasyonsuz hissediyorum.<br/><br/>
        İş yaşamımda son derece stresliyim.<br/><br/>
        Deneyimlemekte en çok zorlandığım duygulardan biri…<br/><br/>
        Son günlerde kendimi … hissediyorum.<br/><br/>
        Daha … olmak istiyorum.<br/><br/>
        Hedeflediğim şeylerden biri…<br/><br/>
        Bugün kendimi … hissettim.<br/><br/>
        Keşke … ile daha iyi bir ilişkim olsaydı.<br/><br/>
        … hakkında daha fazla bilgi sahibi olmak istiyorum.
        `
      },
      {
        title: 'Neleri paylaşma konusunda rahat olabilirim?',
        text: `Paylaştığın bilgiler bizimle güvende. Bu nedenle lütfen içini dökmekten çekinme. Meditopia’da tüm koçlarımız koçluk ve mindfulness eğitimi almış sertifikalı koçlar. Elbette bazı durumlar bir psikoterapist desteği gerektirebilir ve koçun yetkinlik alanının dışında kalabilir. Böyle bir durumda koçun seni bilgilendirecek ve sana nasıl yardımcı olabileceği konusunda açık olacak. Koçunun sana destek olabileceği alanlar ya da yetkinlik alanı konusunda soruların olursa rahatlıkla koçuna sorabilirsin.<br/><br/>
        Masaya yatırdığın konu fark etmeksizin koçun sana sorular sorup konuyla ilgili iç görüler ve bilgiler sağlayarak öz farkındalık kazanmana yardımcı olacak. Kişisel içeriklere ve günlük egzersizlere ek olarak, bu program süresince dayanak olarak kullanabileceğin öz kaynaklarını keşfedeceksin.
        `
      },
      {
        title: 'Programıma nasıl ulaşabilirim?',
        text: `Öncelikle Meditopia Uygulamasını indirdiğinden emin ol. Hesabına giriş yaptıktan sonra uygulamanın ana sayfasında kişisel programını bulacaksın. En iyi deneyim için uygulamanın güncellenmiş versiyonunu kullandığından lütfen emin ol.`
      }
    ]),
    liveSession: withUniqueId([
      {
        title: 'İlk günümde beni neler bekliyor?',
        text: `Öncelikle başlamak için: Uygulamayı indir, koçunla iletişim kur, canlı başlangıç görüşmeni planla ve ilk günün aktivitelerini gözden geçir.<br/><br/>
        Koçun çevrim içi olduğu ilk zaman aralığında, 1 ila 3 saat içinde sana ulaşacak. Koçunun seninle iletişime geçmesini beklemeden ona mesaj göndererek sohbetinizi başlatabilirsin!<br/><br/>
        Koçunla ilk sohbetinizde birbirinizi tanıyacak, isteklerin, hedeflerin, yaşadığın zorluklar ve bu programda neler aradığın üzerine konuşacaksınız. Koçun seni daha iyi anlamak için sana sorular soracak ve hedeflerin doğrultusunda birlikte nasıl çalışacağınızı açıklayacak.<br/><br/>
        Kişisel programını, günlük içeriklerini içeren rehberini ve koçuna erişebileceğin pencereyi uygulamanın ana sayfasında görebilirsin. Bu nedenle lütfen uygulamayı indirmeyi unutma. İlk günlerinde günlük rehberinde bu programa başlamana yardımcı olacak bilgilendirici makaleler, kısa bilgiler ve egzersizler gibi bazı ısınma içeriklerine erişebileceksin.
        `
      },
      {
        title: 'Koçumla iletişimim nasıl ilerleyecek?',
        text: `Program süresince istediğin zaman koçunla iletişime geçebileceksin. Ayrıca her ay bir tane canlı görüşme planlayabilirsin. Bu görüşmeyi yazılı, sesli veya görüntülü olarak dilediğin şekilde yapman mümkün. Koçun hafta içi her gün 09.00-21.00 saatleri arasında çevrim içi olacak ve mesajlarını yanıtlayacak.<br/><br/>
        Günlük etkileşimlerde koçlar genellikle 2-3 saat içinde mesajları yanıtlar. Her koşulda en geç 6 saat içinde bir yanıt almayı bekleyebilirsin. Ayrıca, koçunun çevrim içi olup olmaması fark etmeksizin dilediğin zaman koçuna mesaj gönderebilirsin. Koçun çevrimiçi olduğunda sana geri dönüş yapacaktır. 
        
        `
      },
      {
        title: 'Koç kimdir ve bana nasıl yardımcı olabilir?',
        text: `Koç, öz farkındalık kazanma ve hem kişisel hem de çalışma hayatındaki hedeflerine ulaşma yolculuğunda sana eşlik eden sertifikalı bir profesyoneldir. Koçluk Programı, daha iyi kararlar verip duyguları yönetme ve hedefler belirleyip bunlara ulaşma noktasında sana destek olur. Meditopia’nın kişisel koçları bilinçli farkındalık (mindfulness) alanında uzmandır, dolayısıyla sana kişisel programın aracılığıyla destek olmak için farkındalık araçları ve egzersizleri de sunabilirler.<br/><br/>
        Meditopia’nın kişisel koçları farkındalık ilkelerini kullanarak üyelerimize çalışma yaşamı, ilişkiler, öz farkındalık, kaygı ve stres gibi zorlu duygular ve daha pek çok alanda rehberlik ediyor. Zorlu deneyimlerle ve duygularla başa çıkmak, stres, kaygı, öfke, pişmanlık, yeni alışkanlıklar oluşturmak, kendini daha iyi tanımak, hayaller ve değerleri keşfetmek, daha sağlıklı ilişkiler inşa etmek, öz şefkat ve öz güveni artırmak, sınırlar çizebilmek ve gündelik hayatın zorluklarına çözümler üretmek gibi konular üyelerimizin özel olarak fayda aldıklarını belirttikleri alanlar arasında yer alıyor.
        `
      },
      {
        title: 'Koçumla hangi konular hakkında konuşabilirim?',
        text: `Koçunun sana sağladığı güvenli paylaşım alanında dilediğini paylaşabilirsin. Koçunla üzerine eğilmek istediğin konular yaşam amacından her gün yüzleştiğin sorunlara kadar çeşitlilik gösterebilir. Kısa veya uzun vadeli hedeflerin, duyguların, düşüncelerin, ruh hâlin, ilişkilerin, sorumlulukların ya da genel anlamda hayat hakkında konuşabilirsin.<br/><br/>
        Nereden başlayacağını bilmiyorsan ilk olarak kendini tanıtabilir, o gün nasıl hissettiğinden ya da bu programla hayatında yaratmak istediğin değişikliklerden bahsedebilirsin. Sohbeti başlatmak için başvurabileceğin birkaç örneği aşağıda bulabilirsin:<br/><br/>
        Hedefime ulaşmak konusunda yardıma ihtiyacım var.<br/><br/>
        Kendimi motivasyonsuz hissediyorum.<br/><br/>
        İş yaşamımda son derece stresliyim.<br/><br/>
        Deneyimlemekte en çok zorlandığım duygulardan biri…<br/><br/>
        Son günlerde kendimi … hissediyorum.<br/><br/>
        Daha … olmak istiyorum.<br/><br/>
        Hedeflediğim şeylerden biri…<br/><br/>
        Bugün kendimi … hissettim.<br/><br/>
        Keşke … ile daha iyi bir ilişkim olsaydı.<br/><br/>
        … hakkında daha fazla bilgi sahibi olmak istiyorum.
        `
      },
      {
        title: 'Neleri paylaşma konusunda rahat olabilirim?',
        text: `Paylaştığın bilgiler bizimle güvende. Bu nedenle lütfen içini dökmekten çekinme. Meditopia’da tüm koçlarımız koçluk ve mindfulness eğitimi almış sertifikalı koçlar. Elbette bazı durumlar bir psikoterapist desteği gerektirebilir ve koçun yetkinlik alanının dışında kalabilir. Böyle bir durumda koçun seni bilgilendirecek ve sana nasıl yardımcı olabileceği konusunda açık olacak. Koçunun sana destek olabileceği alanlar ya da yetkinlik alanı konusunda soruların olursa rahatlıkla koçuna sorabilirsin.<br/><br/>
        Masaya yatırdığın konu fark etmeksizin koçun sana sorular sorup konuyla ilgili iç görüler ve bilgiler sağlayarak öz farkındalık kazanmana yardımcı olacak. Kişisel içeriklere ve günlük egzersizlere ek olarak, bu program süresince dayanak olarak kullanabileceğin öz kaynaklarını keşfedeceksin.
        `
      },
      {
        title: 'Programıma nasıl ulaşabilirim?',
        text: `Öncelikle Meditopia Uygulamasını indirdiğinden emin ol. Hesabına giriş yaptıktan sonra uygulamanın ana sayfasında kişisel programını bulacaksın. En iyi deneyim için uygulamanın güncellenmiş versiyonunu kullandığından lütfen emin ol.`
      }
    ])
  },
  en: {
    regular: withUniqueId([
      {
        title: 'What should I expect on my first day?',
        text: `The answer is simple: Download the app, start interacting with your coach, and review the first day’s activities.<br/><br/>
        Your coach will be in touch with you within their first 1-3 active hours. You can message your coach before they connect with you to kickstart your conversation!<br/><br/>
        Your first conversation with your coach will be about getting to know you, your aspirations, goals, challenges, and what you’re looking for in this program. Your coach will ask you questions in order to better understand you and walk you through how you’ll be working toward your goals together.<br/><br/>
        You can see your personalized program on the app’s homepage, so don’t forget to download the app. You’ll also be able to message your coach from there and access some warm-up content like informative talks to help you get started on your new journey. 
        `
      },
      {
        title: 'How does communicating with my coach work?',
        text: `Our coaches are active from 9 am to 9 pm CST. You can expect to hear from your coach within their first active 2-3 hours after your entry into the program. In your day to day interactions, coaches typically respond in less than 90 minutes. In any case, you can expect a response within a maximum 6 hours. You can message your coach whenever you’d like, regardless of whether or not they’re active and they’ll get back to you as soon as they’re online.`
      },
      {
        title: 'Who is a coach and how can they help me?',
        text: `A coach is a certified professional who accompanies you on your journey toward gaining more self-awareness and reaching your personal and professional goals. Coaching supports people in making better choices and managing emotions, as well as setting and achieving goals. Meditopia’s personal coaches are mindfulness experts, which means they’re also able to offer mindfulness tools and exercises to support our members through their personalized program.<br/><br/>
        Using mindfulness principles, Meditopia’s personal coaches guide our members in various areas of their lives from work to relationships, anxiety, and finances. Our members found coaching especially beneficial when coping with challenging experiences and emotions. They felt particularly supported through things like: stress, anxiety, anger, regret, creating new habits, building stronger relationships, finding self-compassion, boosting self-esteem, setting boundaries, and finding solutions to the challenges of daily life. 
        `
      },
      {
        title: 'What can I talk to my coach about?',
        text: `You can share whatever you want in the safe space that you and your coach create together. The topics you want to hone in on with your coach can vary from your goals in life to the problems you face every day. You can discuss short or long term goals, emotions, thoughts, your mood, your relationships, responsibilities, or even just life in general. <br/><br/>
        If you feel like you don’t know where to start, first introduce yourself, talk about how you are and what motivated you to start this program. Here are some examples for what you might say to get the conversation going:<br/><br/>
        I need help with achieving my goal.<br/><br/>
        I feel really unmotivated.<br/><br/>
        I’m super stressed at work.<br/><br/>
        An emotion I find the hardest to experience is… <br/><br/>
        I’ve been feeling….<br/><br/>
        I want to become more…<br/><br/>
        I want to achieve…<br/><br/>
        Today I felt…<br/><br/>
        I wish I had a better relationship with….<br/><br/>
        I’d like to learn more about…
        `
      },
      {
        title: 'What can I be comfortable sharing?',
        text: `The information you share is safe with us, so please feel free to pour your heart out. All our coaches are certified coaches. That said, some matters may require the support of a psychotherapist and may fall beyond the scope of the coach. If that happens, your coach will let you know and clarify how they can be helpful. Please don’t hesitate to ask if you’re unsure. <br/><br/>
        Whatever topic you bring to the table, your coach will help you gain more self-awareness by asking questions and providing you with relevant insight and information. You’ll discover your own resources of support that you can lean on in addition to personalized content and daily exercises.
        `
      },
      {
        title: 'How can I see my program?',
        text: `First, make sure you’ve downloaded the Meditopia App. After you sign in, you’ll find your personalised program on your app’s homepage. Please make sure that you’re using the updated version of the app for the best experience.`
      }
    ]),
    liveSession: withUniqueId([
      {
        title: 'What should I expect on my first day?',
        text: `The answer is simple: Download the app, start interacting with your coach, book your live intro session and review the first day’s activities.<br/><br/>
        Your coach will be in touch with you within their first 1-3 active hours. You can message your coach before they connect with you to kickstart your conversation!<br/><br/>
        Your first conversation with your coach will be about getting to know you, your aspirations, goals, challenges, and what you’re looking for in this program. Your coach will ask you questions in order to better understand you and walk you through how you’ll be working toward your goals together.<br/><br/>
        You can see your personalized program on the app’s homepage, so don’t forget to download the app. You’ll also be able to message your coach from there and access some warm-up content like informative talks to help you get started on your new journey.         
        `
      },
      {
        title: 'How does communicating with my coach work?',
        text: `You'll be able to chat with your coach whenever you like throughout your program. Additionally, you can book one live session each month done through chat, audio, or video depending on your preference. Your coach will be active and able to respond weekdays between 9:00 AM - 9:00 PM CET.<br/><br/>
        You can expect to hear from your coach within their first active 2-3 hours after your entry into the program. In your day to day interactions, coaches typically respond in less than 90 minutes. In any case, you can expect a response within a maximum 6 hours. You can message your coach whenever you’d like, regardless of whether or not they’re active and they’ll get back to you as soon as they’re online.
        `
      },
      {
        title: 'Who is a coach and how can they help me?',
        text: `A coach is a certified professional who accompanies you on your journey toward gaining more self-awareness and reaching your personal and professional goals. Coaching supports people in making better choices and managing emotions, as well as setting and achieving goals. Meditopia’s personal coaches are mindfulness experts, which means they’re also able to offer mindfulness tools and exercises to support our members through their personalized program.<br/><br/>
        Using mindfulness principles, Meditopia’s personal coaches guide our members in various areas of their lives from work to relationships, anxiety, and finances. Our members found coaching especially beneficial when coping with challenging experiences and emotions. They felt particularly supported through things like: stress, anxiety, anger, regret, creating new habits, building stronger relationships, finding self-compassion, boosting self-esteem, setting boundaries, and finding solutions to the challenges of daily life.         
        `
      },
      {
        title: 'What can I talk to my coach about?',
        text: `You can share whatever you want in the safe space that you and your coach create together. The topics you want to hone in on with your coach can vary from your goals in life to the problems you face every day. You can discuss short or long term goals, emotions, thoughts, your mood, your relationships, responsibilities, or even just life in general. <br/><br/>
        If you feel like you don’t know where to start, first introduce yourself, talk about how you are and what motivated you to start this program. Here are some examples for what you might say to get the conversation going:<br/><br/>
        I need help with achieving my goal.<br/><br/>
        I feel really unmotivated.<br/><br/>
        I’m super stressed at work.<br/><br/>
        An emotion I find the hardest to experience is… <br/><br/>
        I’ve been feeling….<br/><br/>
        I want to become more…<br/><br/>
        I want to achieve…<br/><br/>
        Today I felt…<br/><br/>
        I wish I had a better relationship with….<br/><br/>
        I’d like to learn more about…        
        `
      },
      {
        title: 'What can I be comfortable sharing?',
        text: `The information you share is safe with us, so please feel free to pour your heart out. All our coaches are certified coaches. That said, some matters may require the support of a psychotherapist and may fall beyond the scope of the coach. If that happens, your coach will let you know and clarify how they can be helpful. Please don’t hesitate to ask if you’re unsure. <br/><br/>
        Whatever topic you bring to the table, your coach will help you gain more self-awareness by asking questions and providing you with relevant insight and information. You’ll discover your own resources of support that you can lean on in addition to personalized content and daily exercises.        
        `
      },
      {
        title: 'How can I see my program?',
        text: `First, make sure you’ve downloaded the Meditopia App. After you sign in, you’ll find your personalised program on your app’s homepage. Please make sure that you’re using the updated version of the app for the best experience.`
      }
    ])
  },
  fr: {
    regular: withUniqueId([
      {
        title: "À quoi dois-je m'attendre pour mon premier jour?",
        text: `La réponse est simple: Télécharge l'application, commence à échanger avec ton coach, réserve ta session d'introduction en direct et passe en revue les activités de la première journée.<br/><br/>
        Ton coach te contactera dans les 1 à 3 heures lorsqu'il sera en ligne. Tu peux envoyer un message à ton coach avant qu'il ou elle ne se connecte avec toi, pour amorcer la conversation!<br/><br/>
        La première conversation avec ton coach consistera à apprendre à te connaître, à connaître tes aspirations, tes objectifs, tes défis et ce que tu attends de ce programme. Ton coach te posera des questions afin de mieux te comprendre, et t'expliquera comment vous travaillerez ensemble afin d'atteindre tes objectifs.<br/><br/>
        Tu pourras voir ton programme personnalisé sur la page d'accueil de l'application, alors n'oublie pas de la télécharger. Tu pourras également envoyer des messages à ton coach à partir de cette page et accéder à du contenu d'introduction, comme des articles audio informatifs, pour t'aider à démarrer cette nouvelle aventure. 
        `
      },
      {
        title: 'Comment fonctionne la communication avec mon coach?',
        text: `Tout au long de ton programme, tu pourras discuter avec ton coach quand tu le voudras. En plus, tu pourras réserver une session en direct chaque mois, par chat, audio ou vidéo, selon tes préférences. Ton coach sera disponible et pourra te répondre tous les jours de la semaine entre 9h et 21h CET.<br/><br/>
        Tu peux t'attendre à recevoir des nouvelles de ton coach dans les 2 à 3 heures qui suivent ton entrée dans le programme. Pour les interactions quotidiennes, les coachs répondent généralement en moins de 90 minutes. Dans tous les cas, tu peux t'attendre à une réponse dans un délai maximum de 6 heures. Tu peux envoyer un message à ton coach quand tu le souhaites, qu'il soit actif ou non, et il te répondra dès qu'il sera en ligne.`
      },
      {
        title: "Qui est un coach et comment peut-il m'aider?",
        text: `Un coach est un professionnel certifié qui t'accompagne dans ton cheminement vers une meilleure connaissance de toi-même et la réalisation de tes objectifs personnels et professionnels. Le coaching accompagne les personnes pour les aider à faire de meilleurs choix et à mieux gérer leurs émotions, ainsi qu'à fixer et atteindre des objectifs. Les coachs personnels de Meditopia sont des experts de la pleine conscience, ce qui signifie qu'ils sont également en mesure de proposer des outils et des exercices de pleine conscience pour soutenir nos membres dans leur programme personnalisé.<br/><br/>
        En utilisant les principes de la pleine conscience, les coachs personnels de Meditopia guident nos membres dans divers domaines de leur vie, du travail aux relations, en passant par l'anxiété et les finances. Nos membres trouvent le coaching particulièrement bénéfique lorsqu'ils doivent faire face à des expériences et des émotions difficiles. Ils se sentent particulièrement soutenus à travers des éléments tels que: le stress, l'anxiété, la colère, les regrets, la création de nouvelles habitudes, la construction de relations plus fortes, la recherche de l'auto-compassion, le renforcement de l'estime de soi, l'établissement de limites et la recherche de solutions aux défis du quotidien. 
        `
      },
      {
        title: 'De quoi est-ce que je peux parler avec mon coach?',
        text: `Tu peux partager ce que tu veux dans l'espace sécurisé que toi et ton coach créerez ensemble. Les sujets que tu souhaites aborder avec ton coach peuvent aller de tes objectifs de vie aux problèmes que tu rencontres au quotidien. Tu peux discuter de tes objectifs à court ou à long terme, de tes émotions, de tes pensées, de ton humeur, de tes relations, de tes responsabilités ou même de la vie en général.<br/><br/>
        Si tu ne sais pas par où commencer, commence par te présenter, par dire comment tu vas et ce qui te motive à commencer ce programme. Voici quelques exemples de ce que tu pourrais dire pour entamer la conversation:<br/>
        J'ai besoin d'aide pour atteindre mon objectif.<br/>
        Je me sens vraiment démotivé·e.<br/>
        Je suis super stressé·e au travail.<br/>
        L'émotion que je trouve particulièrement difficile à vivre est...<br/>
        Je me sens....<br/>
        Je veux devenir plus...<br/>
        Je veux atteindre...<br/>
        Aujourd'hui, j'ai ressenti...<br/>
        J'aimerais avoir une meilleure relation avec....<br/>
        J'aimerais en savoir plus sur...
        `
      },
      {
        title: "Qu'est-ce que je peux partager en toute confiance?",
        text: `Les informations que tu partages sont en sécurité avec nous, alors n'hésite pas à exprimer ce que tu as sur le cœur. Tous nos coachs sont certifiés. Cela dit, certaines questions peuvent nécessiter le soutien d'un psychothérapeute et dépasser le champ d'action du coach. Si tel est le cas, ton coach te le fera savoir et clarifiera comment il peut t'aider. N'hésite surtout pas à lui demander si tu n'es pas sûr·e.<br/><br/>
        Quel que soit le sujet que tu abordes, ton coach t'aidera à mieux te connaître en te posant des questions et en te fournissant des informations et des points de vue pertinents. Tu découvriras tes propres ressources de soutien sur lesquelles tu pourras t'appuyer, en plus du contenu personnalisé et des exercices quotidiens.
        `
      },
      {
        title: 'Comment est-ce que je peux voir mon programme?',
        text: `Tout d'abord, assure-toi que tu as téléchargé l'application Meditopia. Après t'être connecté·e, tu trouveras ton programme personnalisé sur la page d'accueil de ton application. Vérifie bien que tu utilises la version la plus récente de l'application, pour une expérience optimale.`
      }
    ]),
    liveSession: withUniqueId([
      {
        title: "À quoi dois-je m'attendre pour mon premier jour?",
        text: `La réponse est simple: Télécharge l'application, commence à échanger avec ton coach, réserve ta session d'introduction en direct et passe en revue les activités de la première journée.<br/><br/>
        Ton coach te contactera dans les 1 à 3 heures lorsqu'il sera en ligne. Tu peux envoyer un message à ton coach avant qu'il ou elle ne se connecte avec toi, pour amorcer la conversation!<br/><br/>
        La première conversation avec ton coach consistera à apprendre à te connaître, à connaître tes aspirations, tes objectifs, tes défis et ce que tu attends de ce programme. Ton coach te posera des questions afin de mieux te comprendre, et t'expliquera comment vous travaillerez ensemble afin d'atteindre tes objectifs.<br/><br/>
        Tu pourras voir ton programme personnalisé sur la page d'accueil de l'application, alors n'oublie pas de la télécharger. Tu pourras également envoyer des messages à ton coach à partir de cette page et accéder à du contenu d'introduction, comme des articles audio informatifs, pour t'aider à démarrer cette nouvelle aventure. 
        `
      },
      {
        title: 'Comment fonctionne la communication avec mon coach?',
        text: `Tout au long de ton programme, tu pourras discuter avec ton coach quand tu le voudras. En plus, tu pourras réserver une session en direct chaque mois, par chat, audio ou vidéo, selon tes préférences. Ton coach sera disponible et pourra te répondre tous les jours de la semaine entre 9h et 21h CET.<br/><br/>
        Tu peux t'attendre à recevoir des nouvelles de ton coach dans les 2 à 3 heures qui suivent ton entrée dans le programme. Pour les interactions quotidiennes, les coachs répondent généralement en moins de 90 minutes. Dans tous les cas, tu peux t'attendre à une réponse dans un délai maximum de 6 heures. Tu peux envoyer un message à ton coach quand tu le souhaites, qu'il soit actif ou non, et il te répondra dès qu'il sera en ligne.`
      },
      {
        title: "Qui est un coach et comment peut-il m'aider?",
        text: `Un coach est un professionnel certifié qui t'accompagne dans ton cheminement vers une meilleure connaissance de toi-même et la réalisation de tes objectifs personnels et professionnels. Le coaching accompagne les personnes pour les aider à faire de meilleurs choix et à mieux gérer leurs émotions, ainsi qu'à fixer et atteindre des objectifs. Les coachs personnels de Meditopia sont des experts de la pleine conscience, ce qui signifie qu'ils sont également en mesure de proposer des outils et des exercices de pleine conscience pour soutenir nos membres dans leur programme personnalisé.<br/><br/>
        En utilisant les principes de la pleine conscience, les coachs personnels de Meditopia guident nos membres dans divers domaines de leur vie, du travail aux relations, en passant par l'anxiété et les finances. Nos membres trouvent le coaching particulièrement bénéfique lorsqu'ils doivent faire face à des expériences et des émotions difficiles. Ils se sentent particulièrement soutenus à travers des éléments tels que: le stress, l'anxiété, la colère, les regrets, la création de nouvelles habitudes, la construction de relations plus fortes, la recherche de l'auto-compassion, le renforcement de l'estime de soi, l'établissement de limites et la recherche de solutions aux défis du quotidien. 
        `
      },
      {
        title: 'De quoi est-ce que je peux parler avec mon coach?',
        text: `Tu peux partager ce que tu veux dans l'espace sécurisé que toi et ton coach créerez ensemble. Les sujets que tu souhaites aborder avec ton coach peuvent aller de tes objectifs de vie aux problèmes que tu rencontres au quotidien. Tu peux discuter de tes objectifs à court ou à long terme, de tes émotions, de tes pensées, de ton humeur, de tes relations, de tes responsabilités ou même de la vie en général.<br/><br/>
        Si tu ne sais pas par où commencer, commence par te présenter, par dire comment tu vas et ce qui te motive à commencer ce programme. Voici quelques exemples de ce que tu pourrais dire pour entamer la conversation:<br/>
        J'ai besoin d'aide pour atteindre mon objectif.<br/>
        Je me sens vraiment démotivé·e.<br/>
        Je suis super stressé·e au travail.<br/>
        L'émotion que je trouve particulièrement difficile à vivre est...<br/>
        Je me sens....<br/>
        Je veux devenir plus...<br/>
        Je veux atteindre...<br/>
        Aujourd'hui, j'ai ressenti...<br/>
        J'aimerais avoir une meilleure relation avec....<br/>
        J'aimerais en savoir plus sur...
        `
      },
      {
        title: "Qu'est-ce que je peux partager en toute confiance?",
        text: `Les informations que tu partages sont en sécurité avec nous, alors n'hésite pas à exprimer ce que tu as sur le cœur. Tous nos coachs sont certifiés. Cela dit, certaines questions peuvent nécessiter le soutien d'un psychothérapeute et dépasser le champ d'action du coach. Si tel est le cas, ton coach te le fera savoir et clarifiera comment il peut t'aider. N'hésite surtout pas à lui demander si tu n'es pas sûr·e.<br/><br/>
        Quel que soit le sujet que tu abordes, ton coach t'aidera à mieux te connaître en te posant des questions et en te fournissant des informations et des points de vue pertinents. Tu découvriras tes propres ressources de soutien sur lesquelles tu pourras t'appuyer, en plus du contenu personnalisé et des exercices quotidiens.
        `
      },
      {
        title: 'Comment est-ce que je peux voir mon programme?',
        text: `Tout d'abord, assure-toi que tu as téléchargé l'application Meditopia. Après t'être connecté·e, tu trouveras ton programme personnalisé sur la page d'accueil de ton application. Vérifie bien que tu utilises la version la plus récente de l'application, pour une expérience optimale.`
      }
    ])
  }
}
