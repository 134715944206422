import styled, { css } from 'styled-components'
import { styledColor } from '../../utils'

export const ButtonFeedbackStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  transition: 0.2s ease-in-out;
  pointer-events: none;
`

export const ButtonStyled = styled.button`
  padding: 0.5rem;
  border-radius: 999rem;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  position: relative;
  overflow: hidden;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;

  ${({ colorScheme }) =>
    colorScheme === 'purple'
      ? css`
          background-color: ${styledColor('primary.main')};
        `
      : css`
          background-color: ${styledColor('primary.main', 'secondary.main')};
        `}

  &:disabled {
    background-color: #c5c6ca;
    cursor: default;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      ${ButtonFeedbackStyled} {
        background-color: #fff1;
        opacity: 1;
      }
    }

    &:active {
      ${ButtonFeedbackStyled} {
        background-color: #0001;
        opacity: 1;
      }
    }
  }
`
