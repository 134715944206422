import React from 'react'
import { useDispatch } from 'react-redux'
import { setActiveScreen, useChatSlice } from '../../store'
import { BackIcon, MenuIcon } from '../../icons'
import Box from '../Box'
import IconButton from '../IconButton'
import { HeaderStyled, HeaderWrapperStyled } from './styled'
import DownloadBanner from '../DownloadBanner'
import { getUrlQuery } from '../../utils'

const Header = () => {
  const dispatch = useDispatch()
  const {
    expert: { name, title }
  } = useChatSlice()

  const handleClose = () => {
    window.onCloseClick && window.onCloseClick()
    window.location =
      getUrlQuery('platform', 'app') === 'app'
        ? `${window.location.origin}/close`
        : `${window.location.origin}/${getUrlQuery('lang', 'en')}/close`
  }

  return (
    <HeaderWrapperStyled>
      <HeaderStyled>
        <IconButton onClick={handleClose}>
          <BackIcon />
        </IconButton>

        <Box textAlign="center">
          <Box fontWeight="500">{name}</Box>
          <Box
            fontSize="0.87rem"
            lineHeight="1.125rem"
            color="gray.500"
            _dark={{ color: 'gray.400' }}>
            {title}
          </Box>
        </Box>

        <IconButton
          onClick={() => dispatch(setActiveScreen({ activeScreen: 'coach' }))}>
          <MenuIcon />
        </IconButton>
      </HeaderStyled>

      <DownloadBanner />
    </HeaderWrapperStyled>
  )
}

export default Header
