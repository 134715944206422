const en = {
  sendPlaceholder: 'Type your message',
  messagesCouldntLoad: "We couldn't load your messages right now.",
  tryAgain: 'Try Again',
  errorCode: 'Error Code',
  downloadApp: 'Download the App for best coaching experience',
  download: 'Download',
  bookVideoCall: 'Book a session',
  faqTitle: 'Frequently Asked Questions',
  faqShort: 'FAQ',
  liveSessionMessage:
    'You have a live session available. Click to book a specific time to talk with your coach through video, audio, or text.',
  liveSessionMessageTherapy:
    'You have a live session available. Click to book a specific time to meet with your psychologist.',
  bookLiveSession: 'Book a live session',
  liveBoxTitle: 'Live sessions',
  liveBoxDesc: "Let's book your session",
  extraLiveBoxTitle: 'Do you need more live sessions?',
  extraLiveBoxDesc: 'We can help!',
  extraLiveBoxButton: 'Buy a live session',
  elPriceModalTitle: "You're buying an additional live session.",
  elPriceModalDesc: (price, currency, cardNumber) =>
    `A payment of ${price} ${currency} will be taken from your card ending with ${cardNumber}.`,
  continue: 'Continue',
  cancel: 'Cancel',
  elSuccessModalTitle: 'Congratulations!',
  elSuccessModalDesc:
    'You have a new session credit. Now, you can book your live session.',
  elFailModalTitle: 'Your payment has failed.',
  elFailModalDesc: 'Please add a new card or try again later.',
  addNewCard: 'Add a new card',
  elWarningModalTitle: "We don't have your card information.",
  elWarningModalDesc:
    'Please add a card to your account to purchase live sessions.',
  downloadAppMessageText:
    'You’ve booked your first session. Now download the app and sign-in to reach your personalized program',
  downloadAppMessageButton: 'Download the App'
}

export default en
