import React from 'react'

const PlusVideo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4867 11.947C19.5021 11.963 19.5209 11.9755 19.5416 11.9836C19.5623 11.9916 19.5845 11.9951 19.6067 11.9937C19.6512 11.9941 19.6942 11.9774 19.7267 11.947C19.897 11.7661 19.9943 11.5287 20.0001 11.2803V6.08035C20.0017 5.90936 19.9592 5.74085 19.8766 5.59112C19.794 5.44139 19.6742 5.31551 19.5287 5.22567C19.3832 5.13583 19.217 5.08507 19.0461 5.07829C18.8752 5.07152 18.7055 5.10897 18.5534 5.18702L15.8867 6.52035C15.7202 6.60263 15.5801 6.72995 15.4823 6.88786C15.3844 7.04577 15.3329 7.22794 15.3334 7.41368V9.92035C15.3321 10.0223 15.3479 10.1237 15.3801 10.2203C15.3904 10.2543 15.4117 10.2839 15.4407 10.3044C15.4696 10.3249 15.5046 10.3352 15.5401 10.3337H15.6667C16.3789 10.3339 17.0839 10.4768 17.7399 10.7539C18.396 11.031 18.99 11.4367 19.4867 11.947Z"
        fill="currentColor"
      />
      <path
        d="M14.5333 10.4533C14.571 10.447 14.6051 10.4273 14.6296 10.398C14.6541 10.3687 14.6672 10.3315 14.6667 10.2933V5C14.6667 4.73478 14.5613 4.48043 14.3738 4.29289C14.1862 4.10536 13.9319 4 13.6667 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V12.3333C4 12.5985 4.10536 12.8529 4.29289 13.0404C4.48043 13.228 4.73478 13.3333 5 13.3333H10.7667C10.7985 13.334 10.8298 13.3256 10.857 13.3091C10.8841 13.2925 10.906 13.2686 10.92 13.24C11.2786 12.5371 11.7888 11.9226 12.4136 11.4407C13.0384 10.9588 13.7624 10.6216 14.5333 10.4533Z"
        fill="currentColor"
      />
      <g clipPath="url(#clip0_2517_14750)">
        <path
          d="M15.6654 20.0007C16.5224 20.0007 17.3602 19.7465 18.0728 19.2704C18.7854 18.7942 19.3409 18.1174 19.6688 17.3256C19.9968 16.5338 20.0826 15.6625 19.9154 14.8219C19.7482 13.9813 19.3355 13.2092 18.7295 12.6032C18.1235 11.9972 17.3513 11.5845 16.5108 11.4173C15.6702 11.25 14.7989 11.3359 14.0071 11.6638C13.2153 11.9918 12.5385 12.5472 12.0623 13.2598C11.5862 13.9725 11.332 14.8103 11.332 15.6673C11.332 16.8166 11.7886 17.9188 12.6012 18.7314C13.4139 19.5441 14.5161 20.0007 15.6654 20.0007ZM13.4987 15.3062H15.214C15.2379 15.3062 15.2609 15.2967 15.2778 15.2798C15.2947 15.2628 15.3043 15.2399 15.3043 15.2159V13.5007C15.3043 13.4049 15.3423 13.313 15.41 13.2453C15.4777 13.1776 15.5696 13.1395 15.6654 13.1395C15.7611 13.1395 15.853 13.1776 15.9207 13.2453C15.9884 13.313 16.0265 13.4049 16.0265 13.5007V15.2159C16.0265 15.2399 16.036 15.2628 16.0529 15.2798C16.0698 15.2967 16.0928 15.3062 16.1168 15.3062H17.832C17.9278 15.3062 18.0197 15.3443 18.0874 15.412C18.1551 15.4797 18.1931 15.5715 18.1931 15.6673C18.1931 15.7631 18.1551 15.8549 18.0874 15.9227C18.0197 15.9904 17.9278 16.0284 17.832 16.0284H16.1168C16.0928 16.0284 16.0698 16.0379 16.0529 16.0549C16.036 16.0718 16.0265 16.0948 16.0265 16.1187V17.834C16.0265 17.9298 15.9884 18.0216 15.9207 18.0893C15.853 18.1571 15.7611 18.1951 15.6654 18.1951C15.5696 18.1951 15.4777 18.1571 15.41 18.0893C15.3423 18.0216 15.3043 17.9298 15.3043 17.834V16.1187C15.3043 16.0948 15.2947 16.0718 15.2778 16.0549C15.2609 16.0379 15.2379 16.0284 15.214 16.0284H13.4987C13.4029 16.0284 13.3111 15.9904 13.2434 15.9227C13.1756 15.8549 13.1376 15.7631 13.1376 15.6673C13.1376 15.5715 13.1756 15.4797 13.2434 15.412C13.3111 15.3443 13.4029 15.3062 13.4987 15.3062Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2517_14750">
          <rect
            width="8.66667"
            height="8.66667"
            fill="currentColor"
            transform="translate(11.3333 11.3334)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PlusVideo
