import React from 'react'
import { useDispatch } from 'react-redux'
import { ChatScren, CoachScreen } from './screens'
import { useTranslation } from './contexts'
import {
  fetchConversation,
  fetchLiveSession,
  getCountry,
  getUserDetails
} from './api'
import { useUpdateEffect } from './hooks'
import { immediatelySetInterval, mapMessages, scrollToBottom } from './utils'
import {
  initializeChat,
  setLiveSession,
  setMessages,
  useChatSlice
} from './store'
import { ErrorPage, Loader } from './components'

const App = () => {
  const dispatch = useDispatch()
  const { isLangInitialized, lang } = useTranslation()
  const { isChatInitialized, token, platform, activeScreen } = useChatSlice()
  const syncMessagesInterval = React.useRef(null)
  const langRef = React.useRef()
  const isChatInitializedRef = React.useRef()
  const tokenRef = React.useRef()
  const platformRef = React.useRef()

  langRef.current = lang
  isChatInitializedRef.current = isChatInitialized
  tokenRef.current = token
  platformRef.current = platform

  const syncMessages = () => {
    syncMessagesInterval.current = immediatelySetInterval(async () => {
      try {
        const response = await fetchConversation({
          token: tokenRef.current,
          lang: langRef.current,
          platform: platformRef.current
        })
        const { error, data } = response.data

        if (error) {
          console.error('CHAT ERROR: ', error)
          return
        }

        if (!isChatInitializedRef.current) {
          const [userResponse, countryResponse] = await Promise.all([
            getUserDetails({
              token: tokenRef.current,
              lang: langRef.current
            }),
            getCountry()
          ])

          dispatch(
            initializeChat({
              expert: data.expert,
              messages: data.chats,
              user: userResponse.data.data.user,
              countryCode: countryResponse.data.data.countryCode
            })
          )
          scrollToBottom({ delay: 10 })
        }

        dispatch(setMessages({ messages: mapMessages(data.chats) }))
      } catch (error) {
        console.error('CHAT ERROR: ', error)
      }
    }, 20000)
  }

  const initializeLiveSession = async () => {
    try {
      const response = await fetchLiveSession({
        token: tokenRef.current,
        lang: langRef.current,
        platform: platformRef.current
      })
      const { error, data } = response.data

      if (error) {
        console.error('LIVE SESSION ERROR: ', error)
        return
      }

      dispatch(setLiveSession({ liveSession: data }))
    } catch (error) {
      console.error('LIVE SESSION ERROR: ', error)
    }
  }

  const initialize = () => {
    syncMessages()
    initializeLiveSession()

    return () => {
      clearInterval(syncMessagesInterval.current)
    }
  }

  const scrollOnScreenChange = () => {
    if (activeScreen === 'chat') {
      scrollToBottom({ delay: 10 })
    } else {
      window.scrollTo({ top: 0 })
    }
  }

  useUpdateEffect(initialize, [isLangInitialized])
  useUpdateEffect(scrollOnScreenChange, [activeScreen])

  if (!token) return <ErrorPage />
  if (!isLangInitialized || !isChatInitialized) return <Loader />
  if (activeScreen === 'chat') return <ChatScren />
  if (activeScreen === 'coach') return <CoachScreen />
  return null
}

export default App
