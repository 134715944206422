export const decideSenderType = (sender) => {
  if (sender === 1) return 'expert'
  if (sender === 2) return 'user'
  return 'system'
}

const months = {
  tr: [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık'
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  fr: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  it: [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ],
  de: [
    'Januar',
    'Februar',
    'Marsch',
    'April',
    'Kann',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ],
  es: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  ja: [
    '1月',
    '2月',
    '行進',
    '4月',
    '5月',
    '六月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月'
  ],
  pt: [
    'Janeiro',
    'Fevereiro',
    'Marchar',
    'Abril',
    'Poderia',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  ru: [
    'январь',
    'февраль',
    'Маршировать',
    'Апреля',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'октябрь',
    'ноябрь',
    'Декабрь'
  ]
}

const days = {
  en: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ],
  tr: [
    'Pazartesi',
    'Salı',
    'Çarşamba',
    'Perşembe',
    'Cuma',
    'Cumartesi',
    'Pazar'
  ],
  fr: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
  it: [
    'Lunedi',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
    'Domenica'
  ],
  de: [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ],
  es: [
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
    'Domingo'
  ],
  ja: ['月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日'],
  pt: [
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
    'Domingo'
  ],
  ru: [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье'
  ]
}

export const getTimeString = (date = new Date()) => {
  const hours = date.getHours()
  const mins = date.getMinutes()

  return `${hours > 9 ? hours : `0${hours}`}:${mins > 9 ? mins : `0${mins}`}`
}

export const generateDateString = (datetime, lang) => {
  const date = new Date(parseInt(datetime))
  const day = date.getDate()
  const year = date.getFullYear()
  const monthIndex = date.getMonth()
  const hour = getTimeString(date)

  return `${day} ${months[lang][monthIndex]} ${year} ${hour}`
}

// WILL USE LATER
export default function groupMessages(messages) {
  let result = []

  let currentGroup = {
    sender: 0,
    avatar: '',
    name: '',
    datetime: '',
    isread: '0',
    messages: []
  }

  for (let index = 0; index < messages.length; index++) {
    const message = messages[index]

    if (currentGroup.messages.length === 0) {
      currentGroup = {
        sender: message.sender,
        avatar: message.avatar,
        name: message.name,
        datetime: message.datetime,
        isread: message.isread,
        messages: [message]
      }
      continue
    }

    if (
      currentGroup.messages[currentGroup.messages.length - 1].sender ===
      message.sender
    ) {
      currentGroup = {
        ...currentGroup,
        datetime: message.datetime,
        isread: message.isread,
        messages: [...currentGroup.messages, message]
      }
      continue
    }

    result = [...result, { ...currentGroup }]
    currentGroup = {
      sender: message.sender,
      avatar: message.avatar,
      name: message.name,
      datetime: message.datetime,
      isread: message.isread,
      messages: [message]
    }
  }

  return [...result, { ...currentGroup }]
}

export function separateMessagesByDate(messages) {
  let temporaryMessagesObject = {}

  for (let i = 0; i < messages.length; i++) {
    const messageData = messages[i]

    const date = new Date(parseInt(messageData.datetime))

    if (
      temporaryMessagesObject[
        `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
      ]
    ) {
      temporaryMessagesObject[
        `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
      ] = [
        ...temporaryMessagesObject[
          `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
        ],
        messageData
      ]
    } else {
      temporaryMessagesObject[
        `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
      ] = [messageData]
    }
  }

  return Object.keys(temporaryMessagesObject)
    .map((key) => ({
      date: key,
      messages: temporaryMessagesObject[key]
    }))
    .sort((a, b) => {
      const [aDay, aMonth, aYear] = a.date.split('-')
      const [bDay, bMonth, bYear] = b.date.split('-')
      const aTimeStamp = new Date(
        parseInt(aYear),
        parseInt(aMonth),
        parseInt(aDay)
      )
      const bTimeStamp = new Date(
        parseInt(bYear),
        parseInt(bMonth),
        parseInt(bDay)
      )

      return aTimeStamp - bTimeStamp
    })
}

export function getDailyStructuredMessages(messages) {
  const messagesByDates = separateMessagesByDate(messages)

  return messagesByDates.map((item) => ({
    date: item.date,
    messages: groupMessages(item.messages)
  }))
}

export function getMessagesDateTitle(date, lang) {
  const [day, month, year] = date.split('-')
  const dateObject = new Date(parseInt(year), parseInt(month), parseInt(day))

  return `${day} ${months[lang][parseInt(month)]} ${year} - ${
    days[lang][dateObject.getDay() - 1]
  }`
}
