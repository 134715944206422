import styled, { css } from 'styled-components'
import { styledColor } from '../../utils'
import Box from '../Box'
import Button from '../Button'

export const BackdropStyled = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${styledColor('black.64')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
`

export const ContainerStyled = styled.div`
  background-color: ${styledColor('white.100', 'gray.900')};
  padding: 3rem 1.5rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TitleStyled = styled.div`
  font-weight: 500;
  font-size: 1.325rem;
  line-height: 1.625rem;
  text-align: center;
  color: ${styledColor('gray.700', 'gray.100')};
`

export const DescriptionStyled = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  margin-top: 0.5rem;
  color: ${styledColor('gray.300')};
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  width: 100%;
`

export const PrimaryButton = styled(Button)`
  flex: 1;
  max-width: 12rem;
  padding-left: 2rem;
  padding-right: 2rem;
`

export const CancelButton = styled(Button)`
  background-color: ${styledColor('white.100')};
  border: 1px solid ${styledColor('gray.100')};
  box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.06);
  color: ${styledColor('primary.main', 'secondary.main')};
  width: fit-content;
  padding-left: 2rem;
  padding-right: 2rem;
  ${({ centered }) => !centered && 'margin-left: 0.5rem;'}
`

export const IconContainer = styled(Box)`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 999rem;
  color: ${styledColor('white.100')};
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ type }) => {
    const color = type === 'success' ? 'success' : 'warning'
    return css`
      background-color: ${styledColor(color)};
    `
  }}
`
