import React, { useState } from 'react'
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import Button from '../Button'
import Box from '../Box'
import {
  BackdropStyled,
  PaymentModalStyled,
  ServiceErrorStyled
} from './styled'
import { useTranslation } from '../../contexts'

export default function AdditionPaymentFormModal({ close, onSuccess }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!elements) return

    setLoading(true)
    setError('')

    try {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href
        },
        redirect: 'if_required'
      })

      if (result.error) {
        setError(result.error.message)
        return
      }

      const paymentIntent = result.paymentIntent.id
      onSuccess(paymentIntent)
    } catch (error) {
      console.error('Stripe error:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <BackdropStyled onClick={close}>
      <PaymentModalStyled onClick={(e) => e.stopPropagation()}>
        {Boolean(error) && <ServiceErrorStyled>{error}</ServiceErrorStyled>}
        <form onSubmit={handleSubmit}>
          <Box mb="2">
            <LinkAuthenticationElement />
          </Box>
          <PaymentElement />

          <Box mt="4">
            <Button type="submit" disabled={!stripe || loading}>
              {t('continue')}
            </Button>
          </Box>
        </form>
      </PaymentModalStyled>
    </BackdropStyled>
  )
}
