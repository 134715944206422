import React from 'react'
import { BackdropStyled, ModalStyled } from './styled'

const Modal = ({ isActive, close, children }) => {
  if (!isActive) return null

  return (
    <BackdropStyled onClick={close}>
      <ModalStyled onClick={(e) => e.stopPropagation()}>{children}</ModalStyled>
    </BackdropStyled>
  )
}

export default Modal
