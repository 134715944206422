import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setLiveSession, useChatSlice, decrementCredit } from '../store'
import { useTranslation } from '../contexts'
import { getBrowserName, getOperatingSystem } from '../utils'
import { fetchLiveSession } from '../api'

export const useLiveSession = () => {
  const dispatch = useDispatch()
  const [refreshingInvitation, setRefreshingInvitation] = React.useState(false)
  const { lang } = useTranslation()
  const closeHandler = React.useRef(null)
  const { liveSession, token, platform } = useChatSlice()
  const { isLiveSessionAvailable, liveSessionLink } = liveSession

  const refreshInvitationData = useCallback(async () => {
    try {
      const response = await fetchLiveSession({ token, lang, platform })
      const { data, error } = response.data

      if (error) {
        console.error('REFRESH INVITATION ERROR', error)
        return
      }

      dispatch(setLiveSession({ liveSession: data }))
    } catch (error) {
      console.error('REFRESH INVITATION ERROR', error)
    } finally {
      setRefreshingInvitation(false)
    }
  }, [dispatch, lang, platform, token])

  const openCalendlyWidget = () => {
    window.onLiveSessionButtonClick && window.onLiveSessionButtonClick()
    window.dataLayer &&
      window.dataLayer.push({
        event: 'Click Button',
        type: 'chat',
        click_text: 'Book a live session',
        os: getOperatingSystem(),
        browser: getBrowserName()
      })

    window.Calendly.initPopupWidget({ url: liveSessionLink })

    setTimeout(() => {
      const closeOverlay = document.querySelector('.calendly-close-overlay')
      const closeButton = document.querySelector('.calendly-popup-close')
      closeHandler.current = () => {
        setRefreshingInvitation(true)
        setTimeout(() => refreshInvitationData(), 250)
      }
      closeOverlay.addEventListener('click', closeHandler.current)
      closeButton.addEventListener('click', closeHandler.current)
    }, 50)
  }

  React.useEffect(() => {
    const listener = (event) => {
      if (event.data.event === 'calendly.event_scheduled') {
        console.log('CALENDLY_EVENT_SCHEDULED');
        dispatch(decrementCredit())
      }
    }

    window.addEventListener('message', listener)

    return () => {
      window.removeEventListener('message', listener)
      const closeOverlay = document.querySelector('.calendly-close-overlay')
      const closeButton = document.querySelector('.calendly-popup-close')
      closeOverlay &&
        closeOverlay.removeEventListener('click', closeHandler.current)
      closeButton &&
        closeButton.removeEventListener('click', closeHandler.current)
    }
  })

  return {
    ...liveSession,
    isLiveSessionAvailable: isLiveSessionAvailable && !refreshingInvitation,
    refreshInvitationData,
    openCalendlyWidget
  }
}
