import React from 'react'

export const useMediaQuery = (query) => {
  const [matches, setMatches] = React.useState(false)

  React.useEffect(() => {
    setMatches(window.matchMedia(query).matches)
  }, [])

  return matches
}
