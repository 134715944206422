import React from 'react'

const Check = ({ size = '25' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_0_4)">
        <path
          d="M22.5332 5.6018L11.9423 19.7255C11.7064 20.039 11.4065 20.2979 11.0626 20.4851C10.7183 20.6726 10.3378 20.7836 9.947 20.8114C9.55585 20.8389 9.16361 20.7825 8.79663 20.6455C8.42929 20.5085 8.09602 20.2943 7.81877 20.0174L2.35006 14.5487"
          stroke="currentColor"
          strokeWidth="3.70296"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_4">
          <rect
            width="24"
            height="24"
            fill="currentColor"
            transform="translate(0.727295 0.818176)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Check
