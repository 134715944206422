import React from 'react'
import { IconBox } from '..'
import Box from '../Box'
import { BrandIcon } from '../../icons'
import { ButtonStyled, LiveSessionMessageStyled } from './styled'
import ArrowRight from '../../icons/ArrowRight'
import { useLiveSession } from '../../hooks'
import { useTranslation } from '../../contexts'
import { useChatSlice } from '../../store'

const LiveSessionMessage = () => {
  const { user } = useChatSlice()
  const { openCalendlyWidget } = useLiveSession()
  const { t } = useTranslation()

  return (
    <LiveSessionMessageStyled>
      <IconBox color="primary.main" _dark={{ color: 'white.100' }} mb="0.5rem">
        <BrandIcon />
      </IconBox>

      <Box color="gray.500" mb="1rem" fontSize="0.875rem" lineHeight="1.125rem">
        {user.isTherapyUser ? t('liveSessionMessageTherapy') : t('liveSessionMessage')}
      </Box>

      <ButtonStyled onClick={openCalendlyWidget}>
        {t('bookLiveSession')}{' '}
        <IconBox ml="0.75rem">
          <ArrowRight />
        </IconBox>
      </ButtonStyled>
    </LiveSessionMessageStyled>
  )
}

export default LiveSessionMessage
