import React from 'react'

const DoubleCheck = () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 1L4.07039 8.91956C4.03229 8.96309 3.96532 8.96532 3.92442 8.92442L1 6"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14 1L7 9"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default React.memo(DoubleCheck)
