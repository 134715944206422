import { objectToFormData } from '../utils'
import axios, { countryService, paymentService, v4Service } from './axios'

export const fetchConversation = ({ token, lang, platform }) => {
  let firstopen = 1

  if (typeof window.firstOpen !== 'undefined') {
    firstopen = 0
  }

  window.firstOpen = 'FIRST_OPEN'

  return axios.post(
    `conversation?t=${new Date().getTime()}`,
    objectToFormData({
      jwt_token: token,
      lang,
      platform,
      firstopen
    })
  )
}

export const sendMessage = ({ token, message, lang }) => {
  return axios.post(
    `messagesend?t=${new Date().getTime()}`,
    objectToFormData({ jwt_token: token, message, lang })
  )
}

export const fetchLiveSession = ({ token, lang, platform }) => {
  return axios.post(
    `invitation`,
    objectToFormData({ jwt_token: token, lang, platform })
  )
}

export const getExtraLiveCampaign = ({ token, query = '' }) => {
  return paymentService.get('addition/campaigns' + query, {
    headers: { Authorization: `Bearer ${token}` }
  })
}

export const buyExtraLiveSession = ({ token, priceId }) => {
  return paymentService.post(
    'addition/confirm',
    objectToFormData({ price_id: priceId }),
    { headers: { Authorization: `Bearer ${token}` } }
  )
}

export const buyExtraLiveSessionWithPaymentIntent = ({
  token,
  priceId,
  paymentIntent
}) => {
  return paymentService.post(
    'addition/confirm',
    objectToFormData({ price_id: priceId, payment_intent: paymentIntent }),
    { headers: { Authorization: `Bearer ${token}` } }
  )
}

export const getUserDetails = ({ token, lang }) => {
  return v4Service.get('user', {
    headers: { Authorization: `Bearer ${token}`, lang }
  })
}

export const getCountry = () => {
  return countryService.get('getcountry', {
    headers: {
      Authorization: 'Basic bWVkaXRhc3lvbjoxMDk4'
    }
  })
}
