import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  CoachMenu,
  ColorfulBackground,
  Expert,
  LiveSessionBox,
  ExtraLiveSessionBox
} from '../../components'
import { BackIcon } from '../../icons'
import { setActiveScreen, useChatSlice } from '../../store'
import { BackButtonStyled } from './styled'

const CoachScreen = () => {
  const dispatch = useDispatch()
  const { expert, user } = useChatSlice()

  const goBack = () => dispatch(setActiveScreen({ activeScreen: 'chat' }))

  return (
    <ColorfulBackground>
      <Box position="absolute" zIndex="2" top="2.75rem" left="1.5rem">
        <BackButtonStyled onClick={goBack}>
          <BackIcon />
        </BackButtonStyled>
      </Box>

      <Box px="1.5rem" pb="4rem" maxWidth="30rem" mx="auto">
        <Box pt="4.5rem" />

        <Expert {...expert} />

        <LiveSessionBox />

        <ExtraLiveSessionBox />

        {!user.isTherapyUser && <CoachMenu />}
      </Box>
    </ColorfulBackground>
  )
}

export default CoachScreen
