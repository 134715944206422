import React from 'react'
import { useTranslation } from '../../contexts'
import { CloseIcon } from '../../icons'
import { useChatSlice } from '../../store'
import Box from '../Box'
import FAQ from '../FAQ'
import { FAQ_DATA } from '../FAQ/data'
import Modal from '../Modal'
import { CloseButtonStyled } from './styled'

const FAQModal = ({ isActive, close }) => {
  const { translationLang, t } = useTranslation()
  const {
    liveSession: { isLiveSessionUser }
  } = useChatSlice()
  return (
    <Modal isActive={isActive} close={close}>
      <Box position="relative" pt="2.32rem" px="1rem" pb="2rem">
        <CloseButtonStyled onClick={close}>
          <CloseIcon />
        </CloseButtonStyled>
        <Box
          fontWeight="500"
          fontSize="1.125rem"
          lineHeight="1.625rem"
          textAlign="center"
          mb="2.25rem"
          color="gray.800"
          _dark={{ color: 'white.100' }}>
          {t('faqTitle')}
        </Box>

        <FAQ
          faqs={
            FAQ_DATA[translationLang][
              isLiveSessionUser ? 'liveSession' : 'regular'
            ]
          }
        />
      </Box>
    </Modal>
  )
}

export default FAQModal
