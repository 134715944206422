import React from 'react'

const Expand = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16442 9.38132C8.1431 9.40345 8.11753 9.42104 8.08926 9.43306C8.06098 9.44508 8.03057 9.45127 7.99985 9.45127C7.96912 9.45127 7.93871 9.44508 7.91044 9.43306C7.88216 9.42104 7.8566 9.40345 7.83527 9.38132L3.16327 4.70932C3.07828 4.62363 2.97716 4.55561 2.86574 4.50919C2.75433 4.46278 2.63483 4.43888 2.51413 4.43888C2.39343 4.43888 2.27393 4.46278 2.16252 4.50919C2.0511 4.55561 1.94998 4.62363 1.86499 4.70932C1.6947 4.88063 1.59912 5.11235 1.59912 5.35389C1.59912 5.59544 1.6947 5.82716 1.86499 5.99847L7.0307 11.1642C7.28901 11.4188 7.63715 11.5615 7.99985 11.5615C8.36255 11.5615 8.71068 11.4188 8.96899 11.1642L14.1347 5.99847C14.305 5.82716 14.4006 5.59544 14.4006 5.35389C14.4006 5.11235 14.305 4.88063 14.1347 4.70932C14.0497 4.62363 13.9486 4.55561 13.8372 4.50919C13.7258 4.46278 13.6063 4.43888 13.4856 4.43888C13.3649 4.43888 13.2454 4.46278 13.1339 4.50919C13.0225 4.55561 12.9214 4.62363 12.8364 4.70932L8.16442 9.38132Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default React.memo(Expand)
